<template>
    <div class="wrapper">
      <div>
        <div class="iframe">
          <iframe _ngcontent-dyk-c129="" :src="h5domain" loading="lazy" title="hft.com" allow="clipboard-write" frameborder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen="" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe>
        </div>   
      </div>
    </div>
  </template>
  <script>
  export default {
    name: "H5Wrap",
    data() {
      return {
        h5domain: ''
      };
    },
    computed: {
      isPc(){
        const sUserAgent = navigator.userAgent
        return !(sUserAgent.indexOf('Android') > -1 || sUserAgent.indexOf('iPhone') > -1 || sUserAgent.indexOf('iPod') > -1 || sUserAgent.indexOf('Symbian') > -1)
      }
  },
    beforeMount() {
      this.h5domain = window.configObj.h5JumpUrl+ '?hfttoken=' + this.$route.query.hfttoken
      // this.h5domain = 'http://localhost:8080'+ '?hfttoken=' + this.$route.query.hfttoken
      if (!this.isPc) {
        this.$router.push('Home')
        return
      }
  },
    methods: {
     
    },
  };
  </script>
  
  <style lang="scss" scoped>
  p{
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  h2{
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  .section{
    z-index: 2;
    position: relative;
  }
  .page-container{
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    box-sizing: border-box;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .wrapper{
    background: #000;
    margin-top: 66px;
    padding: 140px 0;
    .iframe{
      width: 390px;
      height: 844px;
      margin: 0px auto;
      position: relative;
      iframe{
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  </style>