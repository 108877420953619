<template>
  <div>
    <component :is="home" />
  </div>
</template>
<script>
import Home1 from '@/pages/theme/home/Home1'
import Home2 from '@/pages/theme/home/Home2'
import Home3 from '@/pages/theme/home/Home3'
import Home4 from '@/pages/theme/home/Home4'
import Home5 from '@/pages/theme/home/Home5'
import Home6 from '@/pages/theme/home/Home6'
import Home7 from '@/pages/theme/home/Home7'
import Home8 from '@/pages/theme/home/Home8'
import Home9 from '@/pages/theme/home/Home9'
export default {
  name: "Home",
  props: ["lang"],
  data() {
    return {
      // banners: [],
      showBannerContent: false,
      curContent: {}
    };
  },
  components: {
    Home1,
    Home2,
    Home3,
    Home4,
    Home5,
    Home6,
    Home7,
    Home8,
    Home9
  },
  // beforeMount() {
  //   this.getBanners();
  // },
  computed: {
    modle() {
      return window.configObj.officialModel
    },
    home() {
      return `Home${this.modle}`
    }
  },
  methods: {
  }
};
</script>

