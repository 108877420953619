<template>
    <div class="wrapper">
      <div class="tech">  
        <div  class="ng-star-inserted" style="">
          <div  >
            <div  class="banner-main-section">
              <div  class="banner-bg" >
                <div  class="grid-container">
                  <div  class="abt-banner-text">
                    <span >HFT AI Platform</span>
                    <h2 >AI-Driven Market Forecasting</h2>
                    <p >Harnessing Advanced Neural Networks for Accurate Market Predictions</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div  >
            <div  class="video-text-section image-text-main-section bg-even">
              <div  class="grid-container">
                <div  class="video-row-data">
                  <div  class="video-container-main"><!---->
                    <img  alt="Image" class="back-and-white ng-star-inserted" src="@/assets/img/v9/tech-2.png"><!---->
                  </div><div  class="text-container-main">
                    <h2 >Our Trained Neural Network: Inspired by Biology, Powered by AI</h2>
                    <div  class="text-data-mind">
                      <p >Our AI system is built upon the principles of neural networks, drawing inspiration from the structure of biological neurons in the human brain. Just like a biological neuron processes signals through its dendrites, axon, and synapses, our artificial neural network mimics this process using layers of interconnected nodes. These artificial neurons receive inputs, apply weights, sum them up, and pass them through an activation function to produce an output. <br> In our trained neural network, each node learns to identify patterns in the data, much like how the brain learns from experience. Through a process known as training, the network adjusts its weights and biases to minimize errors, allowing it to accurately forecast market movements and adapt to changing market dynamics. This blend of biology-inspired architecture and advanced AI techniques enables our system to deliver powerful insights and predictions, driving smarter decision-making.</p>
                    </div><!---->
                    <div  class="text-data-btn"><!---->

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div  >
            <div  class="video-text-section image-text-main-section bg-odd">
              <div  class="grid-container">
                <div  class="video-row-data">
                  <div  class="video-container-main"><!---->
                    <img  alt="Image" class="back-and-white ng-star-inserted" src="@/assets/img/v9/tech-1.png"><!----></div>
                    <div  class="text-container-main">
                      <h2 >Long Short-Term Memory (LSTM)</h2>
                      <div  class="text-data-mind">
                        <p > Our AI leverages <strong>Long Short-Term Memory (LSTM)</strong> networks, a type of advanced recurrent neural network (RNN), specifically designed to analyze and predict complex time series data. LSTM excels at capturing patterns in sequential data, making it highly effective for forecasting market movements. Unlike traditional models, LSTM networks can learn and retain important information over extended periods, which is crucial in financial markets where historical trends and patterns significantly influence future outcomes. By utilizing LSTM, our AI can process and understand vast amounts of market data, identify long-term dependencies, and provide more accurate and reliable predictions. This sophisticated approach allows us to anticipate market shifts and provide strategic insights, helping you make informed decisions with confidence.</p>
                      </div><!---->
                      <div  class="text-data-btn"><!---->

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div  >
              <div  class="video-text-section image-text-main-section bg-even">
                <div  class="grid-container">
                  <div  class="video-row-data">
                    <div  class="video-container-main"><!---->
                      <img  alt="Image" class="back-and-white ng-star-inserted" src="@/assets/img/v9/tech-3.png"><!---->
                    </div>
                    <div  class="text-container-main">
                      <h2 >Convolutional Neural Networks (CNNs): Advanced Pattern Recognition</h2>
                      <div  class="text-data-mind">
                        <p >Our AI employs <strong>Convolutional Neural Networks (CNNs)</strong> to capture and understand intricate patterns within market data. Originally designed for image processing, CNNs have proven exceptionally effective in analyzing sequential data, like financial time series, due to their ability to automatically detect significant features without manual intervention.<br>The architecture of CNNs involves several key components. First, the convolutional layer applies filters to the input data to extract essential features, like trends or anomalies. Then, the ReLU activation function introduces non-linearity, enabling the network to model complex patterns. Following this, pooling layers reduce the dimensionality of the data, simplifying the network while retaining the most crucial information. Finally, the fully connected layer aggregates these extracted features to make predictions.<br>By utilizing CNNs, our AI can sift through vast amounts of data to detect subtle market signals and forecast movements with high accuracy. This deep learning approach enhances our predictive capabilities, helping you stay ahead in dynamic market conditions.</p>
                      </div><!---->
                      <div  class="text-data-btn"><!---->

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div  ><div  class="step-section-main">
              <div  class="grid-container">
                <div  class="header-top">
                  <h2 >Take the Next Steps</h2>
                </div>
                <div  class="btn-grid-row">
                  <div  class="btn-step-main ng-star-inserted">
                    <button @click="$router.push('/hftsignup')"> Register <img  alt="" src="@/assets/img/v9/btn-svg.svg"></button>
                    <p >Join now to gain market insights and stay ahead with AI-powered forecasts!</p>
                  </div>
                  <div  class="btn-step-main ng-star-inserted">
                    <button @click="$router.push('/hftlogin')"> Login <img  alt="" src="@/assets/img/v9/btn-svg.svg">
                    </button>
                    <p >Access your HFT trading Platform. Log in now to get started.</p>
                  </div>
                  <div  class="btn-step-main ng-star-inserted">
                    <button @click="$router.push('/hftcontact')"> Contact us <img  alt="" src="@/assets/img/v9/btn-svg.svg"></button>
                    <p >For any additional questions or support, contact us.</p>
                  </div><!---->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    name: "HftTechnology",
    data() {
      return {
        // banners: [],
      };
    },
    components: {

    },
    // beforeMount() {
    //   this.getBanners();
    // },
    computed: {
    },
    methods: {
    },
  };
  </script>
  
  <style lang="scss" scoped>
  p{
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  h2{
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  .tech{
    margin-top: 66px;
    background-color: #fff;
  }
  .section{
    z-index: 2;
    position: relative;
  }
  .page-container{
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    box-sizing: border-box;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .grid-container{
    max-width: 75rem;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    box-sizing: border-box;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
}
.back-and-white{
  filter: grayscale(100%);
}
.video-text-section{
  padding: 80px 0;
  &.image-text-main-section.bg-even{
    background-color: #fff;
  }
  &.image-text-main-section.bg-odd{
    background-color: #e5e5e5;
  }
  .grid-container{
    max-width: 80rem !important;
  }
  .video-row-data.odd {
    flex-direction: row-reverse;
}
  .video-row-data{
    display: flex;
    align-items: center;
    gap: 60px;
    flex-direction: row-reverse;
    .video-container-main{
        width: 45%;
        img{
            width: 100%;
        }
    }
    .text-container-main{
        width: 55%;
        h2{
          color: #1A1A1A;
          font-size: 40px;
          font-family: arial;
          font-weight: 700;
          margin: 0 0 16px;
          line-height: normal;
        }
        .text-data-mind{
          h2{
            font-family: arial;
            font-weight: 700;
            color: #1A1A1A;
            font-size: 24px;
            margin: 16px 0;
          }
          p{
            font-family: arial;
            color: #1A1A1A;
            font-weight: 400;
            margin: 0;
            font-size: 16px;
          }
        }
    }
  }
}
  .banner-main-section{
    .banner-bg{
      background: url(~@/assets/img/v9/mission-b.jpg);
      background-repeat: no-repeat;
      padding-top: 256px;
      padding-bottom: 160px;
      background-color: #1a1a1a;
      background-size: cover;
      background-position: 45% 18%;
      .abt-banner-text{
        span{
          font-size: 18px;
          font-family: arial;
          color: #fff;
          margin: 0;
          font-weight: 400;
        }
        h2{
          font-size: 60px;
          font-family: arial;
          color: #fff;
          margin: 10px 0;
          font-weight: 700;
        }
        p{
          font-size: 28px;
          opacity: .6;
          letter-spacing: -1px;
          color: #fff;
          font-weight: 700;
          line-height: 105%;
          font-family: arial;
          margin: 0;
        }
      }
    }
  }
  .image-banner-mid{
    margin-top: 80px;
    padding-bottom: 80px;
    background: #f5f5f5;
  }
  .capabilities-section-main{
    background: #1A1A1A;
    padding: 10rem 0px;
    color: #fff;
    .header-top{
      h2{
        color: #fff;
        font-size: 60px;
        font-family: arial;
        margin: 0;
        font-weight: 700;
        margin-bottom: 6rem;
      }
    }
    .card-all-boxs{
      border: 2px solid #404040;
      .box-data-one{
        padding: 48px 45px;
        position: relative;
        display: flex;
        justify-content: space-evenly;
        .inner-box-data{
          h2{
            color: #fff;
            font-size: 32px;
            font-weight: 700;
            margin: 0 0 15px;
            text-align: center;
            line-height: normal;
            position: relative;
          }
          ul{
            padding: 0;
            margin: 0;
            li{
              a{
                font-size: 18px;
                position: relative;
                text-decoration: none;
                text-align: center;
                display: block;
                color: #fff;
                margin-bottom: 6px;
                transition: .3s all;
              }
            }
          }
        }
        &:before{
          position: absolute;
          content: "";
          background-image: url(~@/assets/img/v9/grid-dot.png);
          inset: 25px 45px;
          z-index: 0;
        }
        .url-one{
          margin: 1.2rem 2rem;
          a{
            color: #fff;
            text-decoration: none;
            display: flex;
            align-items: flex-end;
            font-size: 22px;
            font-weight: 700;
            line-height: 1;
            text-align: left;
            white-space: nowrap;
            gap: 20px;
            position: relative;
            transition: .3s all;
          }
        }
      }
      .box-bottom-group{
        display: flex;
        border-top: 2px solid #404040;
        .new-data-box{
          width: 33.33%;
          border-left: 2px solid #404040;
          &:first-child{
            border-left: none;
          }
        }
      }
      .platform-data-box{
        border-top: 2px solid #404040;
        flex-direction: column;
        .header-top{
          h2{
            color: #fff;
            font-size: 32px;
            font-weight: 700;
            margin: 0 0 15px;
            text-align: center;
            line-height: normal;
            position: relative;
          }
        }
        .platform-list{
          ul{
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: center;
            gap: 57px;
            li{
              a{
                font-size: 18px;
                position: relative;
                text-decoration: none;
                text-align: center;
                display: block;
                color: #fff;
                margin-bottom: 6px;
                transition: .3s all;
              }
            }
          }
        }
      }
    }
  }
  .step-section-main{
    padding: 10rem 0rem;
    .grid-container{
      display: block;
    }
    .header-top{
      h2{
        color: #1A1A1A;
        font-family: arial;
        font-weight: 700;
        margin: 0;
        font-size: 40px;
        margin-bottom: 4rem;
      }
    }
    .btn-grid-row{
      display: flex;
      gap: 30px;
      .btn-step-main{
        width: 33.33%;
        button{
          height: 54px;
          width: 100%;
          display: flex;
          align-items: center;
          border: 2px solid #1A1A1A;
          box-shadow: 0 0 inset #1a1a1a;
          color: #1a1a1a;
          font-size: 14px;
          text-align: left;
          transition: .3s all ease-in-out;
          padding: 20px 35px;
          font-weight: 700;
          gap: 15px;
          &:hover{
            border-color: #1a1a1a;
            box-shadow: 400px 0 inset #1a1a1a;
            color: #fff;
          }
        }
        p{
          margin-bottom: .5rem;
          color: #979797;
          font-size: 16px;
          line-height: 1.45;
          font-family: arial;
          font-weight: 400;
        }
      }
    }
  }
  @media(max-width: 1440px) {
  }
  @media(max-width: 1024px) {
    
  }
  @media(max-width: 991px) {
    
  }
  @media (max-width: 768px){
    .banner-main-section{
      .banner-bg{
        .abt-banner-text{
          p{
            font-size: 18px;
          }
          h2{
            font-size: 40px;
            line-height: normal;
            margin-bottom: 15px;
          }
        }
      }
    }
    .video-text-section{
      padding-bottom: 50px;
      .video-row-data{
        flex-direction: column;
        gap: 10px;
        &.odd {
          flex-direction: column;
        }
        .video-container-main{
            width: 100%;
        }
        .text-container-main{
          width:100%;
            h2{
              font-size: 24px;
              &.bgap{
                padding-bottom: 24px;
              }
            }
            .text-data-mind{
              h2{
                font-size: 18px;
                margin: 8px 0;
              }
              p{
                font-size: 14px;
              }
            }
        }
      }
    }
    .capabilities-section-main{
      padding: 5rem 0px;
      .header-top{
        h2{
          font-size: 24px;
        }
      }
      .card-all-boxs{
        border: 2px solid #404040;
        .box-data-one{
          flex-wrap: wrap;
          .inner-box-data{
            h2{
              font-size: 20px;
            }
            ul{
              li{
                a{
                  font-size: 14px;
                }
              }
            }
          }
          .url-one{
            margin: 1.2rem 2rem;
            a{
              font-size: 16px;
            }
          }
        }
        .box-bottom-group{
          flex-wrap: wrap;
          .new-data-box{
            width: 100%;
            border-top: 2px solid #404040;
          }
        }
        .platform-data-box{
          flex-wrap: wrap;
          .header-top{
            h2{
              font-size: 20px;
            }
          }
          .platform-list{
            ul{
              flex-direction: column;
              gap: 10px;
              li{
                a{
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
    .step-section-main{
      padding: 5rem 0rem;
      .header-top{
        h2{
          font-size: 32px;
        }
      }
      .btn-grid-row{
        flex-wrap: wrap;
        gap: 10px;
        .btn-step-main{
          width: 100%;
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    
  }
  </style>