<template>
    <div class="wrapper">
      <header >
      </header>
      <div class="banner">
        <div class="banner-main-section">
          <div class="banner-bg">
            <video class="video-bg" src="@/assets/img/v9/homet1.mp4" autoplay loop muted></video>
            <div class="page-container">
              <div data-aos="fade-up" data-aos-easing="ease-in-out-sine" class="text-data aos-init aos-animate">
                <h2>Empowering Smart Trading,</h2>
                <p>Optimized by AI</p>
                <button tabindex="0" @click="$router.push({name: 'HftSignup'})">Signup</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section aos-init aos-animate" data-aos="fade-up">
        <div>
          <div data-aos="fade-right" data-aos-easing="ease-in-out-sine" class="text-banner-main aos-init aos-animate">
            <div class="page-container">
              <div class="text-header">
                <h2>
                  <span> Discover </span>
                  &nbsp;how HFT empowers predictive trading at scale, optimizing performance across diverse financial markets. </h2>
              </div>
              <div data-aos="fade-up" data-aos-easing="ease-in-out-sine" class="text-image aos-init aos-animate">
                <div style="position: relative; box-sizing: content-box; max-height: 80vh; max-height: 80svh; width: 100%; aspect-ratio: 1.7504196978175715; padding: 40px 0 40px 0;">
                  <iframe _ngcontent-dyk-c129="" src="https://app.supademo.com/embed/cm1hy1wly2zdgououn95d0y2r?embed_v=2" loading="lazy" title="hft.com" allow="clipboard-write" frameborder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen="" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div  data-aos="fade-up" class="section aos-init aos-animate">
        <div>
          <div  class="card-data-main">
            <div  class="page-container">
              <div  data-aos="fade-right" data-aos-delay="300" data-aos-easing="ease-in-out-sine" data-aos-duration="1300" data-aos-offset="300" class="data-header aos-init aos-animate">
                <h2 > The HFT <span >Advantage</span><br >Leverage advanced AI and real-time data analytics to achieve superior trading outcomes at scale. </h2>
              </div>
              <div  class="card-listing-data">
                <div  data-aos="fade" data-aos-offset="500" data-aos-easing="ease-in-out-sine" class="card-inner ng-star-inserted aos-init aos-animate" data-aos-delay="0">
                  <div  class="text-value-inner">
                    <h2 > The HFT Advantage</h2>
                    <p >Leverage advanced AI and real-time data analytics.</p>
                  </div>
                  <div  class="value-text">
                    <h2 > 78 <sup >%</sup>
                    </h2>
                  </div>
                </div>
                <div  data-aos="fade" data-aos-offset="500" data-aos-easing="ease-in-out-sine" class="card-inner ng-star-inserted aos-init aos-animate" data-aos-delay="500">
                  <div  class="text-value-inner">
                    <h2 > High Value</h2>
                    <p >Maximize trading efficiency and boost returns by up to </p>
                  </div>
                  <div  class="value-text">
                    <h2 > 90 <sup >%</sup></h2>
                  </div>
                </div>
                <div  data-aos="fade" data-aos-offset="500" data-aos-easing="ease-in-out-sine" class="card-inner ng-star-inserted aos-init aos-animate" data-aos-delay="1000">
                  <div  class="text-value-inner">
                    <h2 > High Performance</h2>
                    <p >Minimize trade errors and system disruptions by up to</p>
                  </div>
                  <div  class="value-text">
                    <h2 > 98 <sup >%</sup></h2>
                  </div>
                </div>
                <div  data-aos="fade" data-aos-offset="500" data-aos-easing="ease-in-out-sine" class="card-inner ng-star-inserted aos-init aos-animate" data-aos-delay="1500">
                  <div  class="text-value-inner">
                    <h2 >Data-Driven Insights</h2>
                    <p >Utilize advanced data analytics to predict market trends. Accuracy:</p></div>
                    <div  class="value-text">
                      <h2 > 96 <sup >%</sup></h2>
                    </div>
                  </div><!---->
                </div>
              </div>
            </div>
          </div>
      </div>
      <div  data-aos="fade-up" class="section aos-init aos-animate">
        <div>
          <div class="speed-up-section">
            <div class="page-container">
              <div  class="box-data-grid">
                <div  class="box-right-side">
                  <div  data-aos="fade-left" data-aos-delay="300" data-aos-easing="ease-in-out-sine" data-aos-duration="1300" data-aos-offset="300" class="box-one aos-init aos-animate">
                    <p >
                      <span >HFT Analytics </span> 05:55 PM</p>
                      <h2 > How can I assist you today? Feel free to ask any questions about our AI-driven trading system. </h2>
                    </div>
                    <div  data-aos="fade-right" data-aos-delay="300" data-aos-easing="ease-in-out-sine" data-aos-duration="1300" data-aos-offset="300" class="box-one box-two aos-init aos-animate">
                      <p >
                        <span >Antony</span> 5:56 PM</p>
                        <h2 >How do I start an AI-driven trade?</h2>
                      </div><div  data-aos="fade-left" data-aos-delay="300" data-aos-easing="ease-in-out-sine" data-aos-duration="1300" data-aos-offset="300" class="box-one box-three aos-init aos-animate">
                        <p ><span >HFT Analytics </span> 05:56 PM</p>
                        <h2 >To start an AI-driven trade, follow these steps:</h2>
                        <ul >
                          <li >Fund your account and set your trading preferences.</li>
                          <li > Open a position and trust our AI to handle the trade management and make decisions based on market conditions. </li>
                        </ul>
                      </div>
                    </div>
                    <div  data-aos="fade-right" data-aos-delay="300" data-aos-easing="ease-in-out-sine" data-aos-duration="1300" data-aos-offset="300" class="box-left-side aos-init aos-animate">
                      <div  class="text-data">
                        <h2 ><span >Live Support: </span> During Trading Hours</h2>
                        <p > HFT offers expert support whenever the markets are open. Our team is ready to assist you with any inquiries or technical issues during trading hours, ensuring you have the help you need to keep your strategies running smoothly. </p>
                      </div>
                    </div>
              </div>
              <div  class="box-data-grid new-data-grid">
                <div  class="box-left-data">
                  <div  data-aos="fade-right" data-aos-delay="300" data-aos-easing="ease-in-out-sine" data-aos-duration="1300" data-aos-offset="300" class="text-data aos-init aos-animate">
                    <h2 ><span >Advanced</span> AI Insights for Optimal Trading</h2>
                    <p > Utilize our sophisticated AI models to get timely and accurate market predictions. Gain intelligent insights that enhance your trading strategy and ensure smooth, efficient operations. </p>
                  </div>
                </div>
                <div  class="box-right-data">
                  <div  class="graph-new">
                    <img  alt="" src="@/assets/img/v9/graph-new.png">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div  data-aos="fade-up" class="section aos-init aos-animate">
        <div>
          <div class="banner-middle-section">
            <div class="banner-bg">
              <video class="video-bg" src="@/assets/img/v9/family.mp4" autoplay loop muted></video>
              <div  class="page-container">
                <div  class="text-data">
                  <h2  data-aos="fade-up" data-aos-delay="300" data-aos-easing="ease-in-out-sine" class="aos-init aos-animate"> AI-Driven Financial Freedom with HFT </h2>
                  <div  data-aos="fade-up" data-aos-delay="350" data-aos-easing="ease-in-out-sine" data-aos-duration="1300" data-aos-offset="300" class="image-gp-tetx aos-init aos-animate">
                    <p > Our advanced AI solutions streamline your trading, helping you reach your financial goals and enjoy more time with your loved ones. </p>
                    <button @click="$router.push('hftsignup')"  tabindex="0"> Start Your Journey <img  alt="" src="@/assets/img/v9/video-watch.svg"></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div  data-aos="fade-up" class="section aos-init aos-animate">
        <div>
          <div class="contact-section-main">
            <div class="page-container">
              <div class="data-header">
                <div class="text-data">
                  <h2>
                    <span>Unlock </span> the potential of AI-driven trading.
                  </h2>
                  <p> Our advanced algorithms optimize your trades for precision and growth. Simplify your trading experience with innovative technology designed for smarter financial decisions. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import utils from "@/utils/misc"
  import tradingMixin from '@/mixins/tradingMixin'
  export default {
    name: "Home",
    props: ["lang"],
    mixins: [tradingMixin],
    data() {
      return {
        // banners: [],
        showBannerContent: false,
        curContent: {}
      };
    },
    components: {

    },
    // beforeMount() {
    //   this.getBanners();
    // },
    computed: {
      isTimeX() {
        return utils.getLs('agentIdentify') === 'e4ee504c-670f-4c82-a610-5b8aa2351ad7'
      }
    },
    methods: {
      onPlatItem(item) {
        this.curContent = item
        this.showBannerContent = true
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  p{
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  h2{
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  .text-grey {
    color: #91929E!important;
  }  
  .section{
    z-index: 2;
    position: relative;
  }
  .page-container{
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    box-sizing: border-box;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .banner-bg{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    position: relative;
    padding: 16% 0 70px;
    .video-bg{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
    .text-data{
      position: relative;
      z-index: 1;
    }
    &:after{
      position: absolute;
      content: "";
      inset: 0;
      background-image: url(~@/assets/img/v9/bg-after.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 80%;
      height: 100%;
      z-index: 0;
    }
    h2{
      font-size: 94px;
      color: #fff;
      margin: 0 0 25px;
      max-width: 740px;
      font-family: arial;
      line-height: 105%;
    }
    p{
      font-size: 40px;
      color: #fff;
      margin: 0;
      font-weight: 400;
      font-family: arial;
    }
    button{
      color: #fff;
      font-weight: 700;
      background: transparent;
      border: 2px solid #fff;
      font-size: 18px;
      width: 360px;
      padding: 19px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 50px;
      margin-top: 97px;
      position: relative;
      transition: .25s all ease;
      &:hover{
        background: #fff;
          color: #1a1a1a;
      }
    }
  }
  .text-banner-main{
    background: #FEFEFE;
    padding: 118px 0;
    .text-header{
      margin-bottom: 51px;
      h2{
        margin: 0;
        font-size: 40px;
        font-family: arial;
        font-weight: 400;
        color: #1A1A1A;
        line-height: 42px;
        width: 100%;
        max-width: 900px;
        margin-bottom: 4rem;
        letter-spacing: -1px;
        span{
          font-weight: 700;
        }
      }
    }
  }
  .card-data-main{
    background: #FEFEFE;
    position: relative;
    z-index: 4;
    h2{
      margin: 0;
      font-size: 40px;
      font-family: arial;
      font-weight: 500;
      color: #1A1A1A;
      line-height: 42px;
      width: 100%;
      max-width: 800px;
      margin-bottom: 4rem;
      letter-spacing: -1px;
      span{
        font-weight: 700;
      }
    }
    &:after{
      z-index: -1;
      position: absolute;
      background: #000;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      height: 121px;
    }
    .card-listing-data{
      display: flex;
      gap: 25px;
      margin-top: 60px;
      .card-inner{
        background: #FFFFFF;
        box-shadow: 0 3px 7px 3px #00000017;
        padding: 22px;
        width: 25%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        height: auto;
        aspect-ratio: 1 / 1;
        h2{
          margin: 0;
          color: #1A1A1A;
          font-size: 20px;
          font-weight: 700;
          line-height: 25px;
          font-family: arial;
        }
        p{
          margin: 0;
          color: #1A1A1A;
          font-size: 20px;
          font-weight: 400;
          line-height: 25px;
          font-family: arial;
        }
        .value-text{
          h2{
            font-size: 94px;
            font-weight: 700;
            margin: 20px 0 0;
            line-height: 81px;
          }
          sup{
            font-size: 40px;
            color: #979797;
            left: -16px;
            top: -18px;
            font-family: arial;
            position: relative;
          }
        }
      }
    }
  }
  .speed-up-section{
    background: #000000;
    padding: 100px 0;
    .box-data-grid{
      display: flex;
      justify-content: space-between;
      &.new-data-grid{
        margin-top: 126px;
        .box-left-data{
          width: 40%;
          h2{
            margin: 0;
            font-size: 40px;
            font-family: arial;
            font-weight: 400;
            color: #fff;
            line-height: 42px;
            width: 80%;
            max-width: 360px;
            span{
              font-weight: 700;
            }
          }
          p{
            color: #fff;
            font-family: arial;
            font-size: 20px;
            font-weight: 400;
            max-width: 360px;
          }
        }
        .box-right-data{
          width: 60%;
          .graph-new{
            img{
              width: 100%;
            }
          }
        }
      }
      .box-right-side{
        width: 50%;
        .box-one{
          background: #ffffff;
          border-radius: 19px;
          padding: 19px 20px;
          margin-bottom: 22px;
          p{
            margin: 0 0 28px;
            font-size: 16px;
            font-weight: 500;
            font-family: arial;
            color: #808285;
            span{
              font-weight: 700;
              color: #1A1A1A;
            }
          }
          h2{
            margin: 0 0 30px;
            font-family: arial;
            font-weight: 400;
            color: #1A1A1A;
            font-size: 16px;
          }
        }
        .box-two{
          background: #05A6DF;
          width: 80%;
          p{
            color: #fff;
            span{
              font-weight: 700;
              color: #fff;
            }
          }
          h2{
            color: #fff;
          }
        }
        .box-three{
          p{
            margin-bottom: 15px;
          }
          h2{
            margin-bottom: 19px;
          }
        }
      }
      .box-left-side{
        width: 30%;
        .text-data{
          h2{
            margin: 0;
            font-size: 40px;
            font-family: arial;
            font-weight: 400;
            color: #fff;
            line-height: 42px;
            width: 80%;
            span{
              font-weight: 700;
            }
          }
          p{
            color: #fff;
            font-family: arial;
            font-size: 20px;
            font-weight: 400;
            max-width: 360px;
          }
        }
      }
    }
  }
  .banner-middle-section{
    .banner-bg{
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      padding: 100px 0;
      position: relative;

    }
    .video-bg{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }
  }
  .contact-section-main{
    background: #fff;
    padding: 85px 0;
    h2{
      margin: 0;
      font-size: 40px;
      font-family: arial;
      font-weight: 400;
      color: #000;
      line-height: 42px;
      width: 80%;
      max-width: 360px;
      letter-spacing: -1.2px;
    }
    p{
      color: #000;
      font-family: arial;
      font-size: 20px;
      font-weight: 400;
      max-width: 100%;
      letter-spacing: -1.2px;
      margin-top: 35px;
    }
  }
  @media(max-width: 1440px) {
    .banner-bg{
      padding: 21% 0 70px;
    }
    .banner-middle-section{
      .banner-bg{
        padding: 10% 0 70px;
      }
    }
  }
  @media(max-width: 1024px) {
    .text-banner-main{
      .text-header{
        margin-bottom: 30px;
        h2{
          font-size: 32px;
          margin: 0;
          max-width: 100%;
        }
      }
    }
  }
  @media(max-width: 991px) {
    .card-data-main{
      .card-listing-data{
        flex-wrap: wrap;
        margin-top: 30px;
        .card-inner{
          width: 100%;
          h2{
            font-size: 16px;
          }
          p{
            font-size: 16px;
          }
          .value-text{
            h2{
              font-size: 50px;
            }
            sup{
              font-size: 32px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 768px){
    .banner-bg{
      h2{
        font-size: 35px;
        max-width: 700px;
        min-width: 100%;
      }
      p{
        font-size: 18px;
      }
      button{
        margin-top: 50px;
        width: 268px;
        font-size: 18px;
      }
    }
    .card-data-main{
      h2{
        font-size: 30px;
        line-height: normal;
      }
    }
    .speed-up-section{
      .box-data-grid{
        flex-direction: column-reverse;
        .box-right-side{
          width: 100%;
        }
        .box-left-side{
          width: 100%;
          .text-data{
            h2{
              width: 100%;
              span{
                font-weight: 700;
              }
            }
            p{
              font-size: 18px;
            }
          }
        }
        &.new-data-grid{
          margin-top: 30px;
          .box-left-data{
            width: 100%;
            h2{
              font-size: 30px;
              font-weight: normal;
            }
            p{
              font-size: 18px;
            }
          }
          .box-right-data{
            width: 100%;
          }
        }
      }
    }
    .contact-section-main{
      padding: 85px 0;
      h2{
        font-size: 30px;
      }
      p{
        font-size: 18px;
        margin: 10px 0;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .imgarea{
      display: none;
    }
     
    .data24 .data-item h3{
      font-size: 30px;
    }
    .our-feature h2, .howworks h2, .download-area h2{
      font-size: 24px;
    }
    .work-item{
      margin-bottom: 30px;
    }
    .our-feature .single h3{
      font-size: 18px;
    }
  
    .modle2 {
      padding: 0 0 60px; 
      .logoBox{
          width: 100%;
          flex-wrap: wrap;
          .l, .r {
              width: 100%;
              padding: 30px;  
          }
      }
    }
  }
  </style>