<template>
  <div class="header-container">
    <div class="header-top" :class="{transparent: transparent}">
      <div class="v-container">
        <div class="flex align-center flex1">
          <div class="service flex flex1">
            <div class="logo-box">
              <router-link to="/" exact class="navbar-brand">
              <img :src="logoUrl" :alt="appName" style="height:auto; width:46px;">
            </router-link>
            </div>
            <div class="flex just-end flex1">
              <ul class="menus flex align-center">
                <router-link to="/" exact class="navbar-brand">
                  Home
                </router-link>
                <router-link to="/hftabout" exact class="navbar-brand">
                  About
                </router-link>
                <router-link to="/hftmission" exact class="navbar-brand">
                  Mission
                </router-link>
                <router-link to="/hfttechnology" exact class="navbar-brand">
                  Technology
                </router-link>
                <router-link v-if="!userLogin" to="/hftlogin" exact class="navbar-brand">
                  Login
                </router-link>
                <router-link v-if="userLogin" to="/hfth5wrap" exact class="navbar-brand">
                  Trading
                </router-link>
                <router-link to="/hftcontact" exact class="navbar-brand">
                  Contact
                </router-link>
                <router-link v-if="!userLogin" to="/hftsignup" exact class="navbar-signup">
                  <div class="btn">Signup</div>
                </router-link>
                <div v-if="userLogin" class="navbar-signup">
                  <div class="btn" @click="logout">Logout</div>
                </div>
              </ul>
              <div class="toggle-front">
                <el-button type="text" @click="drawer=!drawer">
                  <img v-if="!transparent" src="@/assets/img/toggle-icon02b.svg" alt="">
                  <img v-else src="@/assets/img/v9/toggle-icon01w.svg" alt="">
                </el-button>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-drawer
      class="no-m"
      :visible.sync="drawer"
      :size="280">
      <div slot="title" class="flex align-center">
        <img :src="logoUrl" :alt="appName" style="height:auto; width:46px;">
      </div>
      <ul class="m-menus">
        <li to="/" exact @click="onClickMenu('/')">
            Home
        </li>
        <li to="/hftabout" exact @click="onClickMenu('/hftabout')">
          About
        </li>
        <li to="/hftmission" exact @click="onClickMenu('/hftmission')">
          Mission
        </li>
        <li to="/hfttechnology" exact @click="onClickMenu('/hfttechnology')">
          Technology
        </li>
        <li to="/" exact @click="onClickMenu('/hftlogin')">
          Login
        </li>
        <li to="/hftcontact" exact @click="onClickMenu('/hftcontact')">
          Contact
        </li>
        <li to="/" exact @click="onClickMenu('/hftsignup')">
          <div class="btn m-btn">Signup</div>
        </li>
      </ul>
    </el-drawer>
  </div>
</template>

<script>
import utils from "@/utils/misc"
import i18n from "@/lang";

export default {
  name: "Header",
  props: {
    msg: String,
  },
  data() {
    return {
      flag: false,
      showMenu: false,
      homeHeader: false,
      navloto: false,
      product: false,
      flagImg: '',
      drawer: false
    };
  },
  // components: { userControl },
  mounted() {
    
  },
  computed: {
    transparent() {
      return ["HftAbout", "HftFqa", "HftLogin", "HftSignup", "HftForgotPwd"].indexOf(this.$route.name) > -1
    },
    // userInfo() {
    //   return this.$store.state.userInfo
    // },
    userLogin() {
      return this.$store.state.isLogin
    },
    curLangName() {
      let result = ''
      for (const item of this.langOptions) {
        if (item.lang === this.curLang) {
          result = item.name
        }
      }
      return result
    },
    showWhatsapp() {
      return window.configObj.isShowWhatsapp === '1'
    },
    showLine() {
      return window.configObj.isShowLine === '1'
    },
    showTg() {
      return window.configObj.telegram && window.configObj.telegram.trim() !== '0'
    },
    whatsAppUrl() {
      return window.configObj.whatsapp.split('\n').map(item => ({
        name: item,
        link: `https://wa.me/${item}`
      }))[0]
    },
    lineAppUrl() {
      return window.configObj.line.split('\n').map(item => ({
        name: item,
        link: item
      }))[0]
    },
    // serviceUrl() {
    //   let arr = []
    //   try {
    //     arr = JSON.parse(window.configObj.customerUrl)
    //   } catch (e) {
    //     console.log(e)
    //   }
    //   let _arr = arr.map(item => {
    //     if (this.userInfo.uuid) {
    //       let params = item.params || ''
    //       params = params.replace(/\{USER_ID\}/g, this.userInfo.uuid)
    //       params = params.replace(/\{ACCOUNT\}/g, this.userInfo.mobile || this.userInfo.email)
    //       params = params.replace(/\{NICKNAME\}/g, this.userInfo.nickname)
    //       item.url += `&${params}`
    //     }
    //     return item
    //   })
    //   return _arr.length ? _arr[0].url : ''
    // },
    tgUrl() {
      const arr = window.configObj.telegram.split('\n').map(item => ({
        name: item,
        link: item
      }))
      return arr.length ? arr[0].link : ''
    },
    emailUrl() {
      return window.configObj.customerEmail
    },
    logoUrl() {
      return window.configObj.logoUri
    },
    appName() {
      return window.configObj.appName
    },
    modle() {
      return window.configObj.officialModel
    },
    cofigObj() {
      return window.configObj
    }
  },
  methods: {
    logout() {
      utils.removeLs('hfttoken')
      this.$store.state.isLogin = false
      this.$router.push('/hftlogin')
    },
    onClickMenu(spath) {
      this.drawer=false
      this.$router.push(spath)
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/menuBtn.scss";

.service {
  a {
    color: #0a0a0a;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    font-family: arial;
  }
  .navbar-signup{
    padding-bottom: 6px;
    border: 2px solid #1a1a1a;
    background: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 10px 25px;
    font-family: arial;
    &:hover{
      background-color: #1a1a1a;
      color: #fff;
      .btn{
        background-color: #1a1a1a;
        color: #fff;
      }
    }
    .btn{
      color: #0a0a0a;
      font-size: 16px;
      font-weight: 400;
      text-decoration: none;
      font-family: arial;
    }
  }
  .m-btn{
    padding-bottom: 6px;
    border: 2px solid #1a1a1a;
    background: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 5px 20px;
    display: inline-block;
    font-size: 14px;
  }
  .navbar-brand {
    display: inline-flex;
    align-items: center;
    margin-right: 28px;
    position: relative;
    padding-bottom: 6px;
    &:after{
      position: absolute;
      margin: auto auto 0;
      width: 0%;
      content: "";
      color: transparent;
      background: #1a1a1a;
      transition: background .3s ease-in-out, width .15s ease-in-out;
      inset: auto 0 0;
      height: 2px;
      display: block;
      border: none;
    }
    &:hover{
      &:after{
        width: 100%;
      }
    }
  }
}

.menu {
  display: none;
}
.m-menus{
  margin: 15px;
  border-top: 1px solid #1a1a1a;
  padding-top: 25px;
  li{
    list-style: none;
    margin-bottom: 20px;
    color: #1a1a1a;
    font-weight: 500;
    font-size: 16px;
  }
}
.header-container {
  position: absolute;
  top: 0;
  width: 100%;
  color: #fff;
}

.header-top {
  padding: 6px 0;
  background: #fff;
  border: 1px solid #000;
  position: relative;
  z-index: 10;
  box-sizing: border-box;
  &.transparent{
    background: transparent;
    border:none;
    .service{
      .navbar-brand{
        color: #fff;
        &:after{
          background: #fff;
        }
      }
      .navbar-signup{
        border: 2px solid #fff;
        color: #fff;
        background: transparent;
        &:hover{
          background-color: #fff;
          color: #1a1a1a;
        .btn{
          background-color: #fff;
            color: #1a1a1a;
          }
        }
        .btn{
          color: #fff;
          font-size: 16px;
          font-weight: 400;
          text-decoration: none;
          font-family: arial;
        }
      }
    }
  }
  .item {
    margin-right: 20px;
    font-size: 12px;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 5px 10px;
    color: #fff;

    img {
      margin-right: 6px;
      height: 20px;
    }
  }
}

.header {

  .navbar {
    .modle-1 {
      display: flex;
      justify-content: space-between;
      display: none;

      .logo img {
        height: 45px;
      }
    }

    .navbar-brand {
      color: #fff;
      display: inline-flex;
      align-items: center;
      margin-right: 20px;

      h1 {
        width: 130px;
        height: 100%;
        margin: auto;
        // line-height: 50px;
        display: contents;
        font-size: 40px;
      }
    }

    .collapse-pc {
      background: transparent;
      width: 100%;
      display: inline-flex;
      margin: 0 100px;
      // justify-content: right;
    }

    .collapse-pc-scroll {
      transition: all 0.4s ease-in-out;
      background: #4c007d;
    }
  }

  .navbar2 {
    .collapse-pc-scroll {
      background: #222;
    }
  }

  .nav {
    height: 100%;
    display: flex;
    font-weight: 500;
    font-size: 16px;
    width: 100%;

    a {
      color: #B9C5CA;
      background: none;
      display: flex;
      align-items: center;
      padding: 40px 0;
      flex: 1;

      // margin-left: 35px;
      &:hover {
        transition: all 0.25s ease-in-out;
        color: #fff;
        // color: #fff;
      }

      div {
        text-align: center;
        margin: auto;
        position: relative;
        word-break: break-word;
      }

      .drop-icon {
        color: #999;
        position: relative;
        right: 28px;
        top: -8px;
        transform: translate(50%, 50%);
      }
    }
  }

  .navbar2 {
    a div {
      width: 100px;
    }

    .items {
      color: #fff;

      p {
        color: #fff
      }

      .router-link-exact-active::before,
      .router-link-active::before {
        content: unset;
      }
    }

    .logo img {
      height: 45px;
    }
  }

  .items {

    .router-link-exact-active,
    .router-link-active {
      // color: #0088ff;
      font-size: 16px;
      font-weight: 800;
    }

    .router-link-exact-active.no-act {
      background: none;
    }
  }

  .hide-nav-box {
    width: 123px;

    .hide-nav {
      background: #0f0f0f96;
      position: absolute;
      width: 123px;

      a {
        &:hover {
          background: #111;
          color: #ffb738;
        }

        .router-link-exact-active {
          background: #222;
          color: #ffb738;

          &:after {
            display: none;
          }
        }
      }
    }
  }
}

.scroll {
  height: auto;
  overflow-y: scroll;
  overflow-x: hidden;
}

.navbar-default.scrolled .navbar-header {
  button {
    display: none;
  }

  a {
    display: inline-block;
    padding: 15px 20px;

    img {
      width: auto;
      max-height: 40px;
    }
  }
}

.container {
  display: flex;
  padding: 0;
  justify-content: space-between;
  background: transparent;
  width: 100%;
  padding: 0;
}

.navbar.navbar-default {
  //   background: #0f0f0f;
  border: none;
  padding: 0;
  // padding-bottom: 20px;
  transition: all 0.25s ease-in-out;

  .logo {
    color: #00b5ac;
    max-width: 180px;
  }
}

.header .navbar-default.scrolled {
  // background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  padding: 0;

  .nav a {

    // color: #333;
    div {
      &:hover {
        &:after {
          background: #333;
        }
      }
    }
  }
}

.lang-link {
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;

  i {
    transform: translateY(3px);
  }

  img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }
}

.login-prop {
  border: 1px solid #fff;
  padding: 5px 20px;
  border-radius: 5px;
  color: #91929E;
}
.toggle-front{
  display: none;
}

@media screen and (max-width: 1200px) {

  .login-prop {
    // display: none;
  }
  .toggle-front{
    display: block;
  }
  .menu {
    display: block !important;
  }
  .menus {
    display: none;
  }

  .navbar.navbar-default,
  .navbar-default.scrolled {
    .logo {
      width: auto;
      max-height: 40px;
    }

    .container {
      padding: 5px 5px 5px 10px;
      display: flex;
      flex-direction: column;
    }

    .collapse {
      display: none;
    }

    .navbar-collapse {
      display: block;

      .nav {
        display: flex;
        flex-direction: column;
        background: #fff;

        .router-link-exact-active {
          li {
            color: $primaryColor ;
          }
        }

        .router-link-exact-active.no-act {
          background: none;
        }

        a {
          height: 50px;
          margin-left: 0;

          div {
            font-size: 14px;

            //   line-height: 54px;
            &:hover {
              &:after {
                content: "";
                width: 32px;
                background: none;
                position: absolute;
                bottom: -5px;
                z-index: 99999;
                left: 10px;
                border-radius: 5em;
                color: #333;
                transition: all 0.25s ease-in-out;
              }
            }
          }

          li {
            text-align: center;
            color: #291029;
          }
        }
      }
    }

    .navbar-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      a {
        padding: 15px 20px;
      }
    }
  }

  .nav a li {
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .menu {
    display: block;
  }

  // .logo-box{
  //   display: none !important;
  // }

  .btn{
    background: none;
    border: 2px solid #1A1A1A;
    padding: 5px 20px;
    display: inline-block;
  }
  .navbar-header {
    height: 60px;
  }

  .header .navbar-default.scrolled .nav a li:hover,
  .header .navbar-default .navbar-collapse .nav li:hover {
    &:after {
      background: none;
    }
  }

  .navbar.navbar-default {
    .collapse {
      border: none;

      ul {
        // background: rgba(255, 255, 255, 0.8);
        margin-top: 10px;
      }

      .nav li {
        color: #222;
        margin: auto;
      }
    }
  }

  .adv {
    font-size: 11px;

    .logo {
      width: 143px;
      top: 6px !important;
    }

    span {
      margin: 0 !important;
      padding-right: 12px;
    }
  }

  .header .navbar-default.scrolled {
    // background: #fff;
  }

  .navbar-collapse {
    text-align: center;
  }

  .header .navbar-default.scrolled .navloto {
    background-color: #fff;
  }
}

@media (max-width: 1200px) {
  .header {
    .container {
      padding: 0 15px;

      .hide-nav-box {
        width: 100%;

        .hide-nav {
          width: 100%;
          position: relative;
        }

        .drop-icon {
          right: 35%;
        }
      }
    }

    .modle-1 {
      width: 100%;
      display: flex !important;
    }
  }
}

.gradient {
  background: #347A7B;
  border: none;
  color: #fff;
}

.logo-box {
  display: flex;
  align-items: center;

  .service-list {
    display: flex;
    padding-left: 150px;

    li {
      margin: 0 20px;
      cursor: pointer;

      img {
        width: 40px;
        height: 40px;
      }
      a{
        border: none;
      }
    }
  }

  img {
    width: 134px;
    height: 51px;
    margin-right: 20px;
  }

  .logn-name {
    color: #291029;
    font-weight: bold;
    font-size: 20px;

  }
}

.v-container {
  // position: relative;
  // z-index: 10;
}
</style>
