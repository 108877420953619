<template>
    <div class="wrapper">
      <div>
        <div class="ng-star-inserted" style="">
          <div>
            <div  class="contact-banner-main">
              <div  class="page-container">
                <div  class="header-top-data">
                  <h2 >Contact Us</h2>
                  <h3 >How can we help you?</h3>
                </div><!---->
                <div v-if="isPc"  class="contact-box-grid ng-star-inserted">
                  <div  class="contact-box-main ng-star-inserted">
                    <a  href="#">
                      <router-link to="/hftfqa">
                        <div  class="contact-header">
                          <div  class="text-data">
                            <h2 >Have Questions? Check Our FAQ</h2>
                            <p >Find answers to common questions in our FAQ section. Get quick insights on how we work, our technology, and more!</p>
                          </div>
                          <div  class="text-icon">
                            <img  alt="" src="@/assets/img/v9/icon-con-3.png">
                          </div>
                        </div>
                        <div  class="btn-bottom">
                          <a  href="/hftfaq">Frequently Asked Questions <img  alt="" src="@/assets/img/v9/btn-svg.svg"></a>
                        </div>
                      </router-link>
                    </a>
                  </div>
                <div  class="contact-box-main ng-star-inserted">
                  <a  href="#">
                    <router-link to="/hftcontact">
                      <div  class="contact-header">
                        <div  class="text-data">
                          <h2 >Reach Us via Email</h2>
                          <p >Have specific inquiries? Contact us directly at our email  <a class="inner-link" href="mailto:liveevil1034@gmail.com">liveevil1034@gmail.com</a> . We're here to assist you with any questions or support you need!</p>
                        </div>
                        <div  class="text-icon">
                          <img  alt="" src="@/assets/img/v9/support-email.svg"></div>
                        </div>
                        <div  class="btn-bottom">
                          <a  href="#">liveevil1034@gmail.com <img  alt="" src="@/assets/img/v9/btn-svg.svg"></a>
                        </div>
                    </router-link>
                  </a>
                  </div>
                  <div  class="contact-box-main ng-star-inserted">
                    <a  href="#"  @click="gotochat">
                      <div  class="contact-header">
                        <div  class="text-data">
                          <h2 >Live Support Chat</h2>
                          <p >Need immediate help? Connect with our team via live chat for real-time support and answers to your questions! We're here to help you every step of the way.</p>
                        </div>
                        <div  class="text-icon">
                          <img  alt="" src="@/assets/img/v9/support-chat.svg">
                        </div>
                      </div>
                      <div  class="btn-bottom">
                        <a  href="#">Chat with us! <img  alt="" src="@/assets/img/v9/btn-svg.svg">
                        </a>
                      </div>
                    </a>
                  </div>
                <!---->
                </div>
                <div v-else class="contact-box-grid ng-star-inserted">
                  <el-carousel  indicator-position="outside">
                    <el-carousel-item >
                      <div  class="contact-box-main ng-star-inserted">
                        <a  href="#">
                          <router-link to="/hftfaq">
                            <div  class="contact-header">
                              <div  class="text-data">
                                <h2 >Have Questions? Check Our FAQ</h2>
                                <p >Find answers to common questions in our FAQ section. Get quick insights on how we work, our technology, and more!</p>
                              </div>
                              <div  class="text-icon">
                                <img  alt="" src="@/assets/img/v9/icon-con-3.png">
                              </div>
                            </div>
                            <div  class="btn-bottom">
                              <a  href="/hftfaq">Frequently Asked Questions <img  alt="" src="@/assets/img/v9/btn-svg.svg"></a>
                            </div>
                          </router-link>
                        </a>
                    </div>
                    </el-carousel-item>
                    <el-carousel-item >
                      <div  class="contact-box-main ng-star-inserted">
                        <a  href="javascript:;">
                          <router-link to="/hftcontact">
                            <div  class="contact-header">
                              <div  class="text-data">
                                <h2 >Reach Us via Email</h2>
                                <p >Have specific inquiries? Contact us directly at our email  <a class="inner-link" href="mailto:liveevil1034@gmail.com">liveevil1034@gmail.com</a> . We're here to assist you with any questions or support you need!</p>
                              </div>
                              <div  class="text-icon">
                                <img  alt="" src="@/assets/img/v9/support-email.svg"></div>
                              </div>
                              <div  class="btn-bottom">
                                <a  href="#">liveevil1034@gmail.com <img  alt="" src="@/assets/img/v9/btn-svg.svg"></a>
                              </div>
                          </router-link>
                        </a>
                      </div>
                    </el-carousel-item>
                    <el-carousel-item >
                      <div  class="contact-box-main ng-star-inserted">
                        <a  href="#" @click="gotochat">
                          <div  class="contact-header">
                            <div  class="text-data">
                              <h2 >Live Support Chat</h2>
                              <p >Need immediate help? Connect with our team via live chat for real-time support and answers to your questions! We're here to help you every step of the way.</p>
                            </div>
                            <div  class="text-icon">
                              <img  alt="" src="@/assets/img/v9/support-chat.svg">
                            </div>
                          </div>
                          <div  class="btn-bottom">
                            <a  href="#">Chat with us! <img  alt="" src="@/assets/img/v9/btn-svg.svg">
                            </a>
                          </div>
                        </a>
                      </div>
                    </el-carousel-item>
                  </el-carousel>
                </div>
              <!---->
              </div>
            </div>
          </div>
          <div >
            <div  class="contact-detail-main">
              <div  class="page-container">
                <div  class="section-bottom section-top">
                  <div  class="detail-main-grid">
                    <div  class="detail-main-data">
                      <h2 >Get in touch</h2>
                    </div>
                    <div  class="detail-tab-data">
                      <div  class="tab-new-design">
                        <div  class="tab-navigation">
                          <button @click="curTab='1'"  class="tab-btn" :class="{active: curTab === '1'}"> United Kingdom </button>
                          <button @click="curTab='2'"  class="tab-btn" :class="{active: curTab === '2'}"> International </button>
                        </div>
                        <div  class="tab-group">
                          <div  class="tab ng-star-inserted" :class="{active:  curTab === '1'}">
                            <div  class="tab-data-inner">
                              <div  class="tab-content-new ng-star-inserted"  >
                                <h2 >London  </h2>
                                <p >122 Leadenhall Street</p>
                                <p ><span >London, EC3V 4AB</span><br ></p>
                              </div>
                              <div  class="tab-content-new ng-star-inserted" >
                                <h2 >Manchester</h2>
                                <p >Annie Horniman St, # E31</p>
                                <p ><span >Manchester City, M15 4FN</span><br ></p>
                              </div><!---->
                            </div>
                          </div><!----><!---->
                          <div  class="tab ng-star-inserted" :class="{active:  curTab === '2'}">
                            <div  class="tab-data-inner">
                              <div  class="tab-content-new ng-star-inserted"  >
                                <h2 >Gibraltar  </h2>
                                <p >25, Main Street, P.O. Box 143
                                </p>
                                <p ><span >GX11 1AA </span><br ></p>
                              </div>
                              <div  class="tab-content-new ng-star-inserted" >
                                <h2 >Central, Hong Kong</h2>
                                <p >3/E, The Henderson, 2 Murray Rd</p>
                                <p ><span >Hong Kong</span><br ></p>
                              </div><!---->
                            </div>
                          </div><!----><!---->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>        
      </div>
    </div>
  </template>
  <script>
  export default {
    name: "HftContact",
    data() {
      return {
        curTab: '1'
      };
    },
    components: {

    },
    // beforeMount() {
    //   this.getBanners();
    // },
    computed: {
      isPc(){
        const sUserAgent = navigator.userAgent
        return !(sUserAgent.indexOf('Android') > -1 || sUserAgent.indexOf('iPhone') > -1 || sUserAgent.indexOf('iPod') > -1 || sUserAgent.indexOf('Symbian') > -1)
      },
      serviceItems() {
        const arr = []
        const services = window.configObj.customerUrl
          try{
            const serviceArr = JSON.parse(services)
            serviceArr.map(item => {
              arr.push({
                name: item[this.curLang],
                icon: require('@/assets/img/service-chat.png'),
                path: item.url
              })
            })
          } catch(e) {
            console.log(e)
          }
        return arr
      }
    },
    methods: {
      gotochat() {
        if (this.serviceItems.length)
        window.open(this.serviceItems[0].path)
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  p{
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  h2{
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  .section{
    z-index: 2;
    position: relative;
  }
  .page-container{
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    box-sizing: border-box;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .wrapper{
    background-color: #fff;
    margin-top: 66px;
  }
  .mt-4{
    margin-top: 4rem;
  }
  .contact-banner-main{
    background-color: #1A1A1A;
    color: #fff;
    padding-top: 60px;
    padding-bottom: 80px;
    .header-top-data{
      h2{
        font-size: 48px;
        font-family: arial;
        font-weight: 700;
        margin: 0; 
      }
      h3{
        font-size: 38px;
        font-family: arial;
        font-weight: 700;
        margin: 0;
      }
    }
    .contact-box-grid{
      display: flex;
      gap: 24px;
      margin-top: 32px;
      
    }
  }
  .contact-box-main{
    width: 33.33%;
    padding: 1.25rem;
    display: grid;
    border: 1px solid #fff;
    a{
      .contact-header{
        display: flex;
        justify-content: space-between;
        gap: 10px; 
        .text-data{
          h2{
            margin: 0;
            font-family: arial;
            font-size: 28px;
            font-weight: 700;
            color: #fff; 
          }
          p{
            font-family: arial;
            font-size: 16px;
            font-weight: 400;
            line-height: 30.6px;
            max-width: 296px;
            margin: 0 0 25px;
            color: #fff; 
          }
        }
        .text-icon{
          img{
            width: 64px;
          }
        }
      }
      .btn-bottom{
        a{
          padding-top: 60px;
          color: #fff;
          display: flex;
          gap: 20px;
          img{
            filter: invert(1);
          }
        }
      }
    }

  }
  .contact-detail-main{
    padding: 40px 0 80px;
    background: #fff;
    .detail-main-data{
      width: calc(33.33333% - 1.875rem);
      h2{
        margin: 32px 0 0;
        font-size: 36px;
        font-weight: 700;
        font-family: arial;
        color: #1A1A1A; 
      }
    }
    .section-top{
      margin-top: 32px;
      .detail-main-grid{
          display: flex;
          
      }
    }
    .detail-tab-data{
      width: calc(66.66% - 1.875rem);
      .tab-navigation{
        display: flex;
        gap: 30px;
        border-bottom: 1px solid #E2E2E2;
        margin-top: 32px;
        .tab-btn{
          color: #9b9b9b;
          font-size: 20px;
          font-weight: 700;
          font-family: arial;
          background: none;
          border: none;
          padding: 0 0 24px;
          border-bottom: 4px solid transparent;
          transition: .3s border, .3s color;
          &.active{
            color: #1A1A1A;
            border-color: #1A1A1A;
          }
        }
      }
    }
  }
  .tab-group{
    .tab{
      display: none;
      &.active{
        display: block;
      }
    }
  }
  .tab-data-inner{
    display: grid;
    grid-template-columns: 1fr 1fr;
    transition: all .5s ease;
    .tab-content-new{
      margin: 32px 10px 25px;
      h2{
        font-size: 18px;
        margin: 0 0 5px;
        font-weight: 700;
        font-family: arial;
      }
      p{
        font-size: 18px;
        font-family: arial;
        margin: 0;
        font-weight: 400; 
      }
    }
  }
  @media(max-width: 1440px) {
  }
  @media(max-width: 1024px) {
    
  }
  @media(max-width: 991px) {
    .contact-banner-main{
      .header-top-data{
        h2{
          font-size: 38px;
        }
        h3{
          font-size: 24px;
        }
      }
      .contact-box-grid{
        display: block;
        margin-top: 32px;
        padding: 30px 0;
      }
    }
    .contact-box-main{
      width: 100%;
    }
  }
  @media (max-width: 768px){
    .contact-detail-main{
      .detail-main-data{
        width: 100%;
        h2{
          margin-top: 0;
          font-size: 28px;
        }
      }
      .section-top{
        .detail-main-grid{
          flex-direction: column;
        }
      }
      .detail-tab-data{
        width: 100%;
        .tab-navigation{
          margin-top: 15px;
          .tab-btn{
            color: #9b9b9b;
            font-size: 20px;
            font-weight: 700;
            font-family: arial;
            background: none;
            border: none;
            padding: 0 0 24px;
            border-bottom: 4px solid transparent;
            transition: .3s border, .3s color;
            &.active{
              color: #1A1A1A;
              border-color: #1A1A1A;
            }
          }
        }
      }
      .tab-data-inner{
        grid-template-columns: auto;
        .tab-content-new{
          margin: 15px 0;
          h2{
            font-size: 18px;
            margin: 0 0 5px;
            font-weight: 700;
            font-family: arial;
          }
          p{
            font-size: 18px;
            font-family: arial;
            margin: 0;
            font-weight: 400; 
          }
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    
  }
  </style>