<template>
    <div class="wrapper">
      <div class="mission">  
        <div  class="ng-star-inserted" style="">
          <div  >
            <div  class="banner-main-section">
              <div  class="banner-bg" >
                <div  class="grid-container">
                  <div  class="abt-banner-text">
                    <img  alt="" src="@/assets/img/v9/logo03.png">
                    <h1 >Our Mission:</h1>
                    <h2 >Empowering Market Insights Through Innovation</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div  >
            <div  class="image-banner-mid">
              <div  class="grid-container">
                <div  class="image-width">

                </div>
              </div>
            </div>
          </div>
          <div  >
            <div  class="video-text-section">
              <div  class="grid-container">
                <div  class="video-row-data even">
                  <div  class="video-container-main">
                    <video  src="@/assets/img/v9/sh-1.mp4" autoplay loop muted></video>
                  </div>
                  <div  class="text-container-main">
                    <h2 >Solving Complexity Through Mathematics</h2>
                    <div  class="text-data-mind">
                      <h2 ></h2>
                      <p >Our mission is to tackle the complexities of the financial markets by harnessing the power of advanced mathematical models. We believe that behind every market movement lies a series of intricate mathematical patterns waiting to be deciphered. By leveraging sophisticated algorithms and complex mathematical formulas, we strive to provide deeper insights and more accurate forecasts. Our goal is to demystify the complexities of the market, empowering you with the clarity and precision needed to make informed, strategic decisions in an ever-evolving financial landscape.</p>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
            <div  >
              <div  class="video-text-section">
                <div  class="grid-container">
                  <div  class="video-row-data odd">
                    <div  class="video-container-main">
                      <video  src="@/assets/img/v9/sh-2.mp4" autoplay loop muted></video>
                    </div>
                    <div  class="text-container-main">
                      <h2 class="bgap">Modeling Market Movements for Strategic Insight</h2>
                      <div  class="text-data-mind">
                        <p>We aim to model market data and trading patterns to gain a deeper understanding of market movements in advance. By analyzing vast datasets, including real-time market prices and historical trends, we build predictive models that reveal underlying market dynamics. Our approach focuses on identifying patterns and signals within the data, enabling us to forecast future movements with greater accuracy. Through this proactive modeling, we seek to provide a strategic advantage, helping you navigate the complexities of the market with confidence.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div  >
              <div  class="video-text-section">
                <div  class="grid-container">
                  <div  class="video-row-data even">
                    <div  class="video-container-main">
                      <video  src="@/assets/img/v9/sh-3.mp4" autoplay loop muted></video>
                    </div>
                    <div  class="text-container-main">
                      <h2 class="bgap">Innovating Through Advanced Algorithmic Coding</h2>
                      <div  class="text-data-mind">
                        <p>Our mission extends to innovating through advanced algorithmic coding. We translate complex market theories into actionable strategies. Our code is at the core of our AI, constantly evolving to adapt to market changes and improve predictive accuracy. Through rigorous coding practices, we transform vast amounts of data into precise insights, making sophisticated market analysis accessible and actionable. We are committed to pushing the boundaries of technology to deliver solutions that empower smarter trading decisions.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div >
            <div  class="capabilities-section-main">
              <div  class="grid-container">
                <div  class="inner-section-data">
                  <div  class="header-top">
                    <h2 >Complete Capabilities for AI At-Scale</h2>
                  </div>
                  <div  class="card-all-boxs">
                    <div  class="box-data-one">
                      <div  class="url-one">
                        <a >
                          <img  alt="" src="@/assets/img/v9/ai-studio-icon-light-1.svg"><span >HFT <br > Strategics</span>
                        </a>
                      </div>
                      <div  class="url-one">
                        <a >
                          <img  alt="" src="@/assets/img/v9/ai-studio-icon-light-2.svg"><span >HFT <br > E-Mathematics</span>
                        </a>
                      </div>
                    </div>
                    <div  class="box-bottom-group">
                      <div  class="box-data-one new-data-box ng-star-inserted">
                        <div  class="inner-box-data">
                          <h2 >Data Acquisition</h2>
                          <ul >
                            <li ><a >High-Frequency</a></li>
                            <li ><a >Real-Time</a></li>
                            <li ><a >Preprocessing</a></li>
                            <li ><a >Storage</a></li>
                            <li ><a >Integration</a></li>
                            <li ><a >Quality</a></li>
                          </ul>
                        </div>
                      </div>
                      <div  class="box-data-one new-data-box ng-star-inserted">
                        <div  class="inner-box-data">
                          <h2 >AI Modeling</h2>
                          <ul >
                            <li ><a >Algorithm Design</a></li>
                            <li ><a >Deep Learning</a></li>
                            <li ><a >Feature Engineering</a></li>
                            <li ><a >Optimization</a></li>
                            <li ><a >Neural Networks</a></li>
                            <li ><a >Anomaly Detection</a></li>
                          </ul>
                        </div>
                      </div>
                      <div  class="box-data-one new-data-box ng-star-inserted">
                        <div  class="inner-box-data">
                          <h2 >Deployment</h2>
                          <ul ><li ><a >Scalability</a></li>
                            <li ><a >Forecasting</a></li>
                            <li ><a >Automation</a></li>
                            <li ><a >Monitoring</a></li>
                            <li ><a >Dashboards</a></li>
                            <li ><a >Improvement</a></li>
                          </ul>
                        </div>
                      </div>
                    <!---->
                    </div>
                    <div  class="box-data-one platform-data-box">
                      <div  class="header-top">
                        <h2 >Platform Services</h2>
                      </div>
                      <div  class="platform-list">
                        <ul ><li ><a >Architecture</a></li>
                          <li ><a > Security</a></li>
                          <li ><a > Administration</a></li>
                          <li ><a > Deployment</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div  >
            <div  class="step-section-main">
              <div  class="grid-container">
                <div  class="header-top">
                  <h2 >Take the Next Steps</h2>
                </div>
                <div  class="btn-grid-row">
                  <div  class="btn-step-main ng-star-inserted">
                    <button @click="$router.push('/hftsignup')"> Register <img  alt="" src="@/assets/img/v9/btn-svg.svg"></button>
                    <p >Join now to gain market insights and stay ahead with AI-powered forecasts!</p>
                  </div>
                  <div  class="btn-step-main ng-star-inserted">
                    <button @click="$router.push('/hftlogin')"> Login <img  alt="" src="@/assets/img/v9/btn-svg.svg"></button>
                    <p >Access your HFT trading Platform. Log in now to get started.</p>
                  </div>
                  <div  class="btn-step-main ng-star-inserted">
                    <button @click="$router.push('/hftcontact')"> Contact us <img  alt="" src="@/assets/img/v9/btn-svg.svg"></button>
                    <p >For any additional questions or support, contact us.</p>
                  </div><!---->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    name: "HftMssion",
    data() {
      return {
        // banners: [],
      };
    },
    components: {

    },
    // beforeMount() {
    //   this.getBanners();
    // },
    computed: {
    },
    methods: {
    },
  };
  </script>
  
  <style lang="scss" scoped>
  p{
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  h2{
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  .mission{
    margin-top: 66px;
    background-color: #fff;
  }
  .section{
    z-index: 2;
    position: relative;
  }
  .page-container{
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    box-sizing: border-box;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .grid-container{
    max-width: 75rem;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    box-sizing: border-box;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
}
.video-text-section{
  padding-bottom: 100px;
  .grid-container{
    max-width: 80rem !important;
  }
  .video-row-data.odd {
    flex-direction: row-reverse;
}
  .video-row-data{
    display: flex;
    align-items: center;
    gap: 60px;
    .video-container-main{
        width: 50%;
        video{
          width: 100%;
      }
    }
    .text-container-main{
        width: 50%;
        h2{
          color: #646464;
          font-size: 40px;
          font-family: arial;
          font-weight: 700;
          margin: 0;
          line-height: normal;
          &.bgap{
            padding-bottom: 34px;
          }
        }
        .text-data-mind{
          h2{
            font-family: arial;
            font-weight: 700;
            color: #1A1A1A;
            font-size: 24px;
            margin: 16px 0;
          }
          p{
            font-family: arial;
            color: #1A1A1A;
            font-weight: 400;
            margin: 0;
            font-size: 16px;
          }
        }
    }
  }
}
  .banner-main-section{
    .banner-bg{
      background: url(~@/assets/img/v9/about-b.jpg) no-repeat;
      padding: 256px 0% 160px;
      display: flex;
      align-items: center;
      position: relative;
      background-color: #1a1a1a;
      background-size: cover;
      background-position: top !important;
      .abt-banner-text{
        img {
          max-width: 300px;
          margin-bottom: 10px;
        }
        h1{
          max-width: 500px;
          font-size: 40px;
          letter-spacing: 1px;
          color: #fff;
          font-weight: 900;
          line-height: 105%;
          margin-bottom: 25px;
          font-family: arial;
        }
        h2{
          max-width: 450px;
          font-size: 28px;
          letter-spacing: -1px;
          color: #fff;
          font-weight: 700;
          line-height: 105%;
          margin-bottom: 25px;
          font-family: arial;
        }
      }
    }
  }
  .image-banner-mid{
    margin-top: 80px;
    padding-bottom: 80px;
    background: #f5f5f5;
  }
  .capabilities-section-main{
    background: #1A1A1A;
    padding: 10rem 0px;
    color: #fff;
    .header-top{
      h2{
        color: #fff;
        font-size: 60px;
        font-family: arial;
        margin: 0;
        font-weight: 700;
        margin-bottom: 6rem;
      }
    }
    .card-all-boxs{
      border: 2px solid #404040;
      .box-data-one{
        padding: 48px 45px;
        position: relative;
        display: flex;
        justify-content: space-evenly;
        .inner-box-data{
          h2{
            color: #fff;
            font-size: 32px;
            font-weight: 700;
            margin: 0 0 15px;
            text-align: center;
            line-height: normal;
            position: relative;
          }
          ul{
            padding: 0;
            margin: 0;
            li{
              a{
                font-size: 18px;
                position: relative;
                text-decoration: none;
                text-align: center;
                display: block;
                color: #fff;
                margin-bottom: 6px;
                transition: .3s all;
              }
            }
          }
        }
        &:before{
          position: absolute;
          content: "";
          background-image: url(~@/assets/img/v9/grid-dot.png);
          inset: 25px 45px;
          z-index: 0;
        }
        .url-one{
          margin: 1.2rem 2rem;
          a{
            color: #fff;
            text-decoration: none;
            display: flex;
            align-items: flex-end;
            font-size: 22px;
            font-weight: 700;
            line-height: 1;
            text-align: left;
            white-space: nowrap;
            gap: 20px;
            position: relative;
            transition: .3s all;
          }
        }
      }
      .box-bottom-group{
        display: flex;
        border-top: 2px solid #404040;
        .new-data-box{
          width: 33.33%;
          border-left: 2px solid #404040;
          &:first-child{
            border-left: none;
          }
        }
      }
      .platform-data-box{
        border-top: 2px solid #404040;
        flex-direction: column;
        .header-top{
          h2{
            color: #fff;
            font-size: 32px;
            font-weight: 700;
            margin: 0 0 15px;
            text-align: center;
            line-height: normal;
            position: relative;
          }
        }
        .platform-list{
          ul{
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: center;
            gap: 57px;
            li{
              a{
                font-size: 18px;
                position: relative;
                text-decoration: none;
                text-align: center;
                display: block;
                color: #fff;
                margin-bottom: 6px;
                transition: .3s all;
              }
            }
          }
        }
      }
    }
  }
  .step-section-main{
    padding: 10rem 0rem;
    .grid-container{
      display: block;
    }
    .header-top{
      h2{
        color: #1A1A1A;
        font-family: arial;
        font-weight: 700;
        margin: 0;
        font-size: 40px;
        margin-bottom: 4rem;
      }
    }
    .btn-grid-row{
      display: flex;
      gap: 30px;
      .btn-step-main{
        width: 33.33%;
        button{
          height: 54px;
          width: 100%;
          display: flex;
          align-items: center;
          border: 2px solid #1A1A1A;
          box-shadow: 0 0 inset #1a1a1a;
          color: #1a1a1a;
          font-size: 14px;
          text-align: left;
          transition: .3s all ease-in-out;
          padding: 20px 35px;
          font-weight: 700;
          gap: 15px;
          &:hover{
            border-color: #1a1a1a;
            box-shadow: 400px 0 inset #1a1a1a;
            color: #fff;
          }
        }
        p{
          margin-bottom: .5rem;
          color: #979797;
          font-size: 16px;
          line-height: 1.45;
          font-family: arial;
          font-weight: 400;
        }
      }
    }
  }
  @media(max-width: 1440px) {
  }
  @media(max-width: 1024px) {
    
  }
  @media(max-width: 991px) {
    
  }
  @media (max-width: 768px){
    .banner-main-section{
      .banner-bg{
        box-sizing: content-box;
        height: 110px !important;
        background-position: right !important;
        .abt-banner-text{
          img {
            max-width: 200px;
          }
          h1{
            font-size: 30px;
            background-position: left center;
          }
          h2{
            background-color: #1a1a1a77;
            font-size: 18px;
            background-position: left center;
          }
        }
      }
    }
    .video-text-section{
      padding-bottom: 50px;
      .video-row-data{
        flex-direction: column;
        gap: 10px;
        &.odd {
          flex-direction: column;
        }
        .video-container-main{
            width: 100%;
        }
        .text-container-main{
          width:100%;
            h2{
              font-size: 24px;
              &.bgap{
                padding-bottom: 24px;
              }
            }
            .text-data-mind{
              h2{
                font-size: 18px;
                margin: 8px 0;
              }
              p{
                font-size: 14px;
              }
            }
        }
      }
    }
    .capabilities-section-main{
      padding: 5rem 0px;
      .header-top{
        h2{
          font-size: 24px;
        }
      }
      .card-all-boxs{
        border: 2px solid #404040;
        .box-data-one{
          flex-wrap: wrap;
          .inner-box-data{
            h2{
              font-size: 20px;
            }
            ul{
              li{
                a{
                  font-size: 14px;
                }
              }
            }
          }
          .url-one{
            margin: 1.2rem 2rem;
            a{
              font-size: 16px;
            }
          }
        }
        .box-bottom-group{
          flex-wrap: wrap;
          .new-data-box{
            width: 100%;
            border-top: 2px solid #404040;
          }
        }
        .platform-data-box{
          flex-wrap: wrap;
          .header-top{
            h2{
              font-size: 20px;
            }
          }
          .platform-list{
            ul{
              flex-direction: column;
              gap: 10px;
              li{
                a{
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
    .step-section-main{
      padding: 5rem 0rem;
      .header-top{
        h2{
          font-size: 32px;
        }
      }
      .btn-grid-row{
        flex-wrap: wrap;
        gap: 10px;
        .btn-step-main{
          width: 100%;
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    
  }
  </style>