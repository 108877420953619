<template>
    <div class="wrapper">
      <div>
        <div class="fqa">
          <div class="banner-main-section">
            <div class="banner-bg">
              <div class="grid-container">
                <div class="abt-banner-text">
                  <h2>Frequently Asked Questions</h2>
                </div>
              </div>
            </div>
          </div>
          <div class="data">
            <div class="faq-data-main">
              <div class="grid-container">
                <input v-model="searchText" type="text" placeholder="Search your Question" id="search" class="search-bar ng-tns-c155-5 ng-pristine ng-valid ng-touched">
                <div class="all-faq-data">
                  <div class="info-tap-according">
                    <div class="cate" v-for="cate in qaListRes" :key="cate.cate">
                      <h2>{{cate.cate}}</h2>
                      <div class="item" v-for="item in cate.items" :key="item.order">
                        <div class="accordion-header" @click="curExpandIndex === item.order ?curExpandIndex = '': curExpandIndex = item.order">
                          <div class="dis-grid">
                            <div class="title-top">
                              <h2>{{item.order}}. {{item.q}}</h2>
                            </div>
                            <div class="icon-right">
                              <fa-icon v-if="curExpandIndex !== item.order"  class="ng-fa-icon arrow ng-tns-c155-6"><svg role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus fa-w-14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg></fa-icon>
                              <fa-icon  v-else class="ng-fa-icon arrow ng-tns-c155-6"><svg role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="minus" class="svg-inline--fa fa-minus fa-w-14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg></fa-icon>
                            </div>
                          </div>
                          <div class="accordion-body" :class="{active: curExpandIndex===item.order}">
                            <p v-html="item.a"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    name: "HftQa",
    data() {
      return {
        searchText: '',
        curExpandIndex: '',
        qaList: [
          {
            cate: 'General',
            items: [
              {
                order: 1,
                q: 'What is HFT?',
                a: 'HFT is an advanced AI-powered trading platform designed to automate the process of stock trading. Our AI selects, manages, and closes positions for users based on real-time market analysis. HFT charges a 10% fee on profits made from each successful trade, with no subscription fees.'
              },
              {
                order: 2,
                q: 'How does AI trading work on HFT?',
                a: 'HFT\'s AI analyzes market conditions after the market opens, preparing itself for trading. Users can select from a list of stocks prioritized by the AI based on predictability and confidence levels. Stocks with higher confidence percentages are more likely to yield profits and appear at the top of the list. The AI automatically closes the position at the optimal time to maximize returns.'
              },
              {
                order: 3,
                q: 'What types of assets can I trade on HFT?',
                a: 'Currently, HFT supports trading in a select number of stocks that are highly predictable based on the AI\'s analysis. These stocks are carefully chosen to maximize the effectiveness of the AI\'s predictive algorithms, increasing the likelihood of profitable trades.'
              },
              {
                order: 4,
                q: 'Can I manually trade on HFT?',
                a: 'Yes, you can open trades manually. However, we strongly recommend relying on the AI for trading decisions. The platform is specifically designed for automated trading, and manual trades may carry more risk. \n The AI\'s predictive algorithms increase the likelihood of successful trades, offering up to a <strong> 98% chance of success. </strong>'
              },
              {
                order: 5,
                q: 'Does HFT offer a demo mode?',
                a: 'Yes, HFT offers a demo mode for new users. This allows you to familiarize yourself with the platform and experience AI trading without risking real funds. The demo mode simulates real market conditions, helping you understand how the AI operates before you start live trading.'
              },
              {
                order: 6,
                q: 'How long does it take for the AI to start trading after market opens?',
                a: 'After the market opens, the AI takes some time to analyze market conditions. Once the analysis is complete, usually within a short period, you can open an AI-managed position.'
              },
              {
                order: 7,
                q: 'Are there any country restrictions on using HFT?',
                a: 'No, HFT does not impose any country restrictions. The platform is available globally. However, it is your responsibility to ensure that using HFT complies with local laws and regulations in your country.'
              },
            ]
          },
          {
            cate: 'Deposits and Withdrawals',
            items: [
              {
                order: '8',
                q: 'How do I deposit funds into HFT?',
                a: 'You can deposit funds using the following methods <ul><li>Cryptocurrencies:<ul><li>Bitcoin (BTC)</li><li>Ethereum (ETH)</li><li>USDT (Tether)</li><li>Binance Coin (BNB)</li><li>Tron (TRX)</li></ul></li><li>USDT Networks Supported: Ethereum, BSC, and Tron networks.</li><li>Bank Transfer:<ul><li>Available for users in certain countries.</li><li>Account verification is required for bank transfer deposits.</li></ul></li></ul> To deposit: <ol><li>Navigate to the "Deposit" section in your account.</li><li>	Select your preferred deposit method (cryptocurrency or bank transfer).</li><li>	Follow the instructions provided to complete your deposit.</li></ol>'
              },
              {
                order: '9',
                q: 'How do I withdraw funds from HFT?',
                a: 'Withdrawals can be made using: <ul><li>Cryptocurrencies:<ul><li>BTC, ETH, USDT, BNB, TRX.</li><li>Withdrawals are generally instant; however, network congestion can affect the timing.</li><li>A gas fee is charged, depending on the chosen network and its current conditions.</li></ul></li><li>Bank Transfer:<ul><li>If you deposited via bank transfer, you can withdraw funds through bank transfer.</li><li>Processing time is typically 24-48 hours.</li></ul></li></ul><p><strong>Important Note:</strong> If you deposit funds via bank transfer, you are required to withdraw via bank transfer. Withdrawal via cryptocurrency is not available in this case.</p>'
              },
              {
                order: '10',
                q: 'Are there any withdrawal limits or requirements?',
                a: '<p>. <strong> Verification:</strong> Required if you withdraw more than $100,000 USD per day.</p><p>. <strong>Fees:</strong> Gas fees apply to cryptocurrency withdrawals, varying based on network conditions.</p><p>. <strong>No Withdrawal Limits:</strong> There are no specific withdrawal limits for verified accounts.</p>'
              },
              {
                order: '11',
                q: 'Is there a minimum deposit amount?',
                a: 'Yes, the minimum deposit amounts depend on the deposit method:<ul><li>Cryptocurrencies:<ul><li>BTC: 0.001 BTC</li><li>ETH: 0.01 ETH</li><li>USDT: 10 USDT</li><li>BNB: 0.05 BNB</li><li>TRX: 100 TRX</li></ul></li><li>Bank Transfer: Minimum amounts may vary; please check the deposit page for details.</li></ul>'
              },
              {
                order: '12',
                q: 'Is there a minimum withdraw amount?',
                a: 'Yes, Minimum amount for Bitcoin network is 50 USD and 20 USD for other cryptocurrencies and 100 USD for wire transfer.'
              },
              {
                order: '13',
                q: 'Can I change my deposit or withdrawal method?',
                a: '<ul><li>1.	If you deposited via cryptocurrency, you can withdraw via any supported cryptocurrency.</li><li>2.	If you deposited via bank transfer, you must withdraw via bank transfer.</li></ul>'
              }
            ]
          },
          {
            cate: 'AI Trading Features',
            items: [
              {
                order: '15',
                q: 'Can I cancel an AI-managed trade before it closes?',
                a: 'Yes, you have the option to cancel an open AI position before the AI closes it. However, we recommend allowing the AI to manage the trade to completion to maximize profit potential. If you choose to cancel, you assume full responsibility for any potential losses.'
              },
              {
                order: '16',
                q: 'What leverage does HFT offer?',
                a: 'HFT offers a leverage ratio of up to 50X. This allows you to control positions that are up to 20 times larger than your actual capital, which can significantly increase potential profits (or losses). '
              },
              {
                order: '17',
                q: 'Can I customize the AI\'s trading strategy?',
                a: 'No, the AI\'s trading algorithms are proprietary and designed to maximize success rates based on extensive market data analysis. Customization of the AI\'s strategy is not available to users.'
              },
              {
                order: '18',
                q: 'Can I open multiple AI trading positions at once?',
                a: 'No, when you open an AI-managed position on HFT, you cannot open additional positions until the current one is closed. The AI focuses on managing a single position using your available account balance to ensure optimized results. Once the AI closes the position, you can open a new one.'
              },
              {
                order: '19',
                q: 'Does HFT offer an automatic trading function?',
                a: 'Yes, HFT provides an automatic trading function that you can activate at no additional cost or subscription. To enable this feature, follow these steps:<ul><li>Activation: You can activate the automatic trading function in the Account Settings section of your HFT account.</li><li>Automatic Trading: Once activated, the AI will automatically open a trading position using your entire account balance when the market opens and the AI is ready to trade.</li><li>Hands-Free Trading: The AI will manage the entire trade, from opening to closing, optimizing for profit. In simple terms, this system operates like an autopilot, trading on your behalf without the need for manual input.</li></ul>'
              }
            ]
          },
          {
            cate: 'Security and Verification',
            items: [
              {
                order: '20',
                q: 'What security measures does HFT provide?',
                a: 'HFT offers multiple security features to protect your account:<ul><li><strong>Google 2-Factor Authentication (2FA):</strong> Adds an extra layer of security during login.</li><li><strong>SMS Withdrawal Codes:</strong> Receive a verification code via SMS during withdrawals for added security.</li></ul> We highly recommend enabling these security measures.'
              },
              {
                order: '21',
                q: 'Is verification required on HFT?',
                a: '<p>Verification is not mandatory unless:</p><ul><li>You use bank transfer for deposits or withdrawals.</li><li>You withdraw more than $100,000 USD per day.</li></ul><p>Verification enhances account security and complies with regulatory requirements.</p>'
              },
              {
                order: '22',
                q: 'What should I do if I forget my password?',
                a: 'you forget your password:1.	Click on the \'Forgot Password\' link on the login page.Enter your registered email address. Follow the instructions sent to your email to reset your password.'
              },
              {
                order: '23',
                q: 'What if I lose access to my 2FA device?',
                a: 'Contact Support, Our support team will assist you in disabling 2FA so you can regain access.'
              },
              {
                order: '24',
                q: 'How secure is my personal and financial information?',
                a: 'HFT employs advanced security measures to protect your data:<ul><li>Encryption: All data is encrypted during transmission and storage.</li><li>Secure Servers: Data is stored on secure servers with robust firewalls.</li><li>Privacy Compliance: We adhere to strict privacy policies and comply with relevant data protection regulations.</li></ul>'
              },
              {
                order: '25',
                q: 'Can I have multiple accounts on HFT?',
                a: 'Yes, you can have multiple accounts on HFT. However, we recommend managing your accounts carefully to avoid confusion and ensure compliance with our platform\'s terms and conditions.'
              },
              {
                order: '26',
                q: 'What happens if I experience a technical issue?',
                a: 'Contact Support: <ul><li>Email: support@HFT.com</li><li>Live Chat: Available on the HFT website.</li></ul>'
              }
            ]
          },
          {
            cate: 'Referral',
            items: [
              {
                order: '27',
                q: 'Does HFT offer a referral program?',
                a: 'Yes, HFT offers a referral commission program, You receive 5% of your referral\'s profit from each trade (if the profit exceeds $1).'
              },
              {
                order: '28',
                q: 'How does the referral commission work if my referral loses money on trades?',
                a: 'Referral commissions are only earned on profitable trades. If your referral does not make a profit on a trade, there is no commission earned for that trade.'
              }
            ]
          }
        ],
      };
    },
    components: {

    },
    // beforeMount() {
    //   this.getBanners();
    // },
    computed: {
      qaListRes(){
        if (!this.searchText.trim()) {
          return this.qaList
        }
        const list = []
        for (const cate of this.qaList) {
          const obj = {
            cate: cate.cate,
            items: []
          }
          for (const item of cate.items) {
            if (item.q.toLowerCase().indexOf(this.searchText.toLowerCase())>-1 || item.a.toLowerCase().indexOf(this.searchText.toLowerCase())>-1) {
              obj.items.push(item)
            }
          }
          if (obj.items.length) {
            list.push(obj)
          }
        }
        return list
      }
    },
    methods: {
    },
  };
  </script>
  
  <style lang="scss" scoped>
  p{
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  h2{
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-size: 2em;
  }
  .wrapper{
    line-height: 1.5;
  }
  .grid-container{
      max-width: 75rem;
      width: 100%;
      margin: 0 auto;
      padding: 0 15px;
      box-sizing: border-box;
      padding-left: 1.75rem;
      padding-right: 1.75rem;
  }
  .page-container{
      max-width: 1440px;
      width: 100%;
      margin: 0 auto;
      padding: 0 15px;
      box-sizing: border-box;
      padding-left: 1.75rem;
      padding-right: 1.75rem;
  }
  .banner-main-section{
    .banner-bg{
      background: url(~@/assets/img/v9/faq-b.jpg) no-repeat;
      padding: 256px 0% 160px;
      display: flex;
      align-items: center;
      position: relative;
      background-color: #1a1a1a;
      background-size: cover;
      background-position: 45% 18%;
      .abt-banner-text{
        img {
          max-width: 300px;
          margin-bottom: 10px;
        }
        h2{
          font-size: 40px;
          font-family: arial;
          color: #fff;
          margin: 0;
          font-weight: 700;
          text-align: center;
        }
      }
    }
  }
  .faq-data-main{
    padding: 80px 0;
    background: #fff;
  }
  .search-bar{
    width: 400px;
    height: 40px;
    font-size: 1.7rem;
  }
  .svg-inline--fa.fa-w-14 {
      width: 0.875em;
  }
  .mt-1{
    margin-top: 1rem;
  }
  .item{
    cursor: pointer;
    margin-bottom: 10px;
    overflow: hidden;
  }
  .all-faq-data{
    font-family: arial;
    margin: 32px 0;
    .info-tap-according{
      display: flex;
      flex-direction: column;
      gap: 20px;
      .accordion-header{
        border-bottom: 1px solid #b3b3b3;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        .dis-grid{
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 100px;
          .title-top{
            h2{
              font-size: 24px;
              font-family: arial;
              font-weight: 400;
              line-height: normal;
              margin: 5px 0;
            }
          }
          
        }
      }
      .accordion-body{
        opacity: 0;
        transition: all .3s ease;
        height: 0;
        &.active{
          opacity: 1;
          height: auto;
        }
        p{
          font-weight: 400;
          font-size: 20px;
          font-family: arial;  
        }
      }
    }
  }
  @media(max-width: 1440px) {
  }
  @media(max-width: 1024px) {
    
  }
  @media(max-width: 991px) {
  }
  @media (max-width: 768px){
    .banner-bg{
      background-position: right !important;
    }
    .search-bar{
      width: 100%;
      height: 40px;
      font-size: 1.7rem;
      margin: 0 auto;
    }
    h2{
      font-size: 1.5em;
    }
    .all-faq-data{
      margin: 24px 0;
      .info-tap-according{
        gap: 10px;
        .accordion-header{
          .dis-grid{
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            .title-top{
              h2{
                font-size: 14px;
              }
            }
            
          }
        }
        .accordion-body{
          p{
            font-size: 14px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    
  }
  </style>