<template>
    <div class="wrapper">
      <div>
        <div class="data-front-sign-up signin-main-page">
          <div  class="page-container">
            <div  class="data-grid-main">
              <div  class="text-data">
                <img  alt="" class="atp-class ng-star-inserted" src="@/assets/img/v9/atp.png"><!---->
                <div  class="data-txt ng-star-inserted">
                  <p > Log in to HFT AI Trading and experience the power of AI-driven strategies for automated, seamless trading and real-time market insights. </p>
                  <p >Stay ahead of the markets by signing in now.</p>
                </div><!----><!---->
              </div>
              <!---->
              <el-form
                ref="form"
                class="form-data-main"
                :rules="rules"
                :model="form">
                <h2>Sign Up</h2>
                <div class="tab" v-if="configObj.accountType==='0'">
                  <span class="item" :class="{active: form.type==='EMAIL'}" @click="form.type='EMAIL'">{{ $t(101) }}</span>
                  <span class="item" :class="{active: form.type==='MOBILE'}" @click="form.type='MOBILE'">{{ $t(102) }}</span>
                </div>
                <div class="item" v-show="form.type==='EMAIL'">
                  <div class="label mb5">{{ $t(101) }}</div>
                  <el-form-item prop="email">
                    <el-input v-model="form.email">
                    </el-input>
                  </el-form-item>
                </div>
                <div class="item" v-show="form.type==='MOBILE'">
                  <div class="label mb5">{{ $t(102) }}</div>
                  <el-form-item
                    prop="mobile">
                      <el-input v-model="form.mobile">
                      <el-select slot="prepend" v-model="form.areaCode" class="code-select" filterable popper-class="bfff">
                        <el-option
                          v-for="item in countryList"
                          :key="item.countryCode"
                          :label="'+'+item.areaCode"
                          filterable
                          :value="item.areaCode"
                        >{{ item.englishName+'+'+item.areaCode }}</el-option>
                      </el-select>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="item" v-if="configObj.isOpenGlobalVerifyCode==='1'&&configObj.isOpenRegVerifyCode==='1'">
                  <div class="label mb5">{{ $t(122) }}</div>
                  <el-form-item
                    prop="verifyCode">
                      <el-input v-model="form.verifyCode">
                        <el-button slot="append" :disabled="text!==sendText" type="text" @click="sendCode">
                          <span :class="{'countDown': sendText !== text}">{{ sendText }}</span>
                        </el-button>
                    </el-input>
                  </el-form-item>
                </div>
                <div>
                  <div class="label mb5">{{ $t(105) }}</div>
                  <el-form-item
                    prop="password">
                      <el-input type="password" v-model="form.password" show-password></el-input>  
                  </el-form-item>
                </div>
                <div v-if="configObj.isShowInviteCode==='1'">
                  <div class="label mb5 cursor flex align-center" @click="inviteCodeClick">{{ $t(116) }} <span v-if="configObj.isNeedRealInviteCode!=1">({{ $t(208) }})</span><i class="icon cursor" v-if="configObj.isNeedRealInviteCode!=1" :class="{'el-icon-caret-bottom': !inviteCodeExpand,'el-icon-caret-top':inviteCodeExpand}"></i> </div>
                  <el-form-item
                    v-show="inviteCodeExpand"
                    prop="inviteCode">
                      <el-input v-model="form.inviteCode"></el-input>  
                  </el-form-item>
                </div>
                <div class="pt20 pb10"><el-button type="primary" @click="doSubmit" :loading="loading">{{ $t(95) }}</el-button></div>
              </el-form>
              <div  class="active-btn-url">
                <a  href="#">
                  <router-link to="/hftlogin">
                    Login
                  </router-link>
                </a>
                <a  href="#" class="active">
                  <router-link to="/hftsignup">Register</router-link>
                </a>
                <a  href="#">
                  <router-link to="/hftforgotpwd">Password recovery</router-link>
                </a>
              </div>
            </div>
          </div>
        </div>    
      </div>
    </div>
  </template>
  <script>
  import loginMixins from '@/mixins/login.js'
import { login } from '@/api'
import utils from '@/utils/misc'
import { register, getMobileCodeWithoutToken, getEmailCodeWithoutToken } from '@/api'
  export default {
    name: "HftSignup",
    mixins: [loginMixins],
    data() {
      return {
        curTab: '1',
        loading: false,
        form: {
          email: '',
          type: window.configObj.accountType === '2'?  'EMAIL' : 'MOBILE',
          mobile: '',
          areaCode: window.configObj.defaultAreaCode,
          password: '',
          inviteCode: '',
          verifyCode: '',

        },
        inviteCodeExpand: window.configObj.isNeedRealInviteCode === '1',
        sendText: '',
        text: this.$t('123'),
        timer: null
      };
    },
    computed: {
      configObj() {
        return window.configObj
      },
      rules() {
        return {
        ...this.commonRules,
        inviteCode:[{
          validator: (rule,value,callback) => {
            if (!value && window.configObj.isNeedRealInviteCode === '1') {
              return callback(new Error(this.$t(this.isNOWDEX ? '308x' : 308)))
            }
            callback()
          }
        }]
      }
       },
      countryList() {
        return this.$store.state.countryList
      },
      isPc(){
        const sUserAgent = navigator.userAgent
        return !(sUserAgent.indexOf('Android') > -1 || sUserAgent.indexOf('iPhone') > -1 || sUserAgent.indexOf('iPod') > -1 || sUserAgent.indexOf('Symbian') > -1)
      }
    },
    beforeMount() {
      if (this.countryList.length === 0) {
        this.$store.dispatch('getCountries')
      }
      this.sendText = utils.getLs('sendText') || this.text
      this.setTimer()
    },
    methods: {
    inviteCodeClick() {
      if (window.configObj.isNeedRealInviteCode === '1') return
      this.inviteCodeExpand = !this.inviteCodeExpand
    },
    doSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const obj = {...this.form}
          if (obj.verifyCode === '') { // 验证码没开启默认传：123456
            obj.verifyCode = '123456'
          }
          register(obj).then(res => {
            this.showSuccessMsg(this.$t(307))
            this.$router.push({name: 'HftLogin', query: { mobile:obj.type==='MOBILE'?obj.mobile:undefined,areaCode:obj.type==='MOBILE'?obj.areaCode:undefined,email: obj.type==='EMAIL'?obj.email:undefined,}})
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    setTimer() {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        if (isNaN(this.sendText)) return
        if (this.sendText <= 1) {
          this.sendText = this.text
          clearInterval(this.timer)
          utils.removeLs('sendText')
        } else {
          this.sendText--
          utils.setLs('sendText', this.sendText)
        }
      }, 1000)
    },
    sendCode() {
      if (this.loading) return
      const key = this.form.type === 'MOBILE'? 'mobile':'email'
      this.$refs.form.validateField(key, (error) => {
        if (!error) {
          const obj = {
            scene: 'REGISTER',
            [key]: this.form[key]
          }
          if (this.form.type === 'MOBILE') {
            obj.areaCode = this.form.areaCode
          }
          this.loading = true
          const api = this.form.type === 'EMAIL' ? getEmailCodeWithoutToken: getMobileCodeWithoutToken
          api(obj).then(() => {
            this.sendText = 60
            this.setTimer()
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
  };
  </script>
  
  <style lang="scss" scoped>
  p{
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  h2{
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  .section{
    z-index: 2;
    position: relative;
  }
  .page-container{
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    box-sizing: border-box;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .wrapper{
    background-color: #fff;
    font-family: arial;
  }
  .mt-4{
    margin-top: 4rem;
  }
  .tab{
    margin: 40px 0px 20px 0px;
    color: #707A8A;
    font-size: 16px;
    .item{
      display: inline-block;
      cursor: pointer;
      padding: 8px 24px;
      margin-right: 12px;
      &.active{
        background-color: #dce1d9;
        color: #1E2329;
        border-radius: 8px;
      }
    }
  }
  .data-front-sign-up{
    padding: 200px 0;
    background: #000;
    .data-grid-main{
      display: flex;
      gap: 20px;
      .text-data{
        width: 33.33%;
        p{
          font-family: arial;
          color: #fff;
          font-weight: 400;
          margin: 32px 0;
          font-size: 18px;
        }
      }
      .form-data-main{
        box-sizing: content-box;
        width: 33.33%;
        background-color: #fff;
        padding: 48px;
        h2{
          font-size: 40px;
          margin: 0 0 40px 0;
        }
      }
      .active-btn-url{
        display: flex;
        flex-direction: column;
        border-left: 1px solid #fff;
        height: 90px;
        gap: 10px;
        a{
          text-decoration: none;
          color: #f2f2f2;
          font-family: arial;
          font-size: 16px;
          font-weight: 400;
          margin-left: 16px;
          position: relative;
          &.active{
            font-weight: 600;
            &:after{
              position: absolute;
              content: "";
              left: -17px;
              background: #fff;
              width: 4px;
              height: 31px;
              top: -4px;
            }
          }
        }
      }
    }
  }
  .atp-class{
    width: 160px;
    height: auto;
}
  @media(max-width: 1440px) {
  }
  @media(max-width: 1024px) {
    
  }
  @media(max-width: 991px) {
    .data-front-sign-up{
      padding: 150px 0;
      .data-grid-main{
        flex-wrap: wrap;
        .text-data{
          width: 100%;
        }
        .form-data-main{
          width: 100%;
          padding: 28px;
          h2{
            font-size: 30px;
            margin: 0 0 30px 0;
          }
        }
        .active-btn-url{
          display: none;
        }
      }
    }
  }
  @media (max-width: 768px){
    
  }
  @media screen and (max-width: 1200px) {
    
  }
  </style>