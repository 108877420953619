<template>
  <footer>
    <div class="page-container">
      <div class="">
        <div class="navs-box">
          <ul class="flex">
            <router-link to="/" exact class="navbar-brand">
              Home
            </router-link>
            <router-link to="/hftabout" exact class="navbar-brand">
              About Us
            </router-link>
            <router-link to="/hftmission" exact class="navbar-brand">
              Mission
            </router-link>
            <router-link v-if="!isLogin" to="/hftsignup" exact class="navbar-brand">
              Register
            </router-link>
            <router-link v-if="!isLogin" to="/hftlogin" exact class="navbar-brand">
              Login
            </router-link>
            <router-link to="/hftfqa" exact class="navbar-brand">
              FAQ
            </router-link>
            <router-link to="/hftterms" exact class="navbar-brand">
              Terms of use
            </router-link>
            <router-link to="/hftcontact" exact class="navbar-brand">
              Contact Us
            </router-link>
          </ul>
        </div>
      </div>
      <div class="footer-copyright">
        <p class="col-12 copyright">
        © 2024 <span>{{appName}}</span>. All Rights Reserved.
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      navs: [
        {
          title: this.$t("nav5"),
          routes: [
            {
              path: "about",
              pathName: this.$t("nav6"),
            },
            {
              path: "about",
              pathName: this.$t("nav7"),
            },
            {
              path: "about",
              pathName: this.$t("nav8"),
            },
            {
              path: "about",
              pathName: this.$t("nav9"),
            },
          ],
        },
        {
          title: this.$t("nav14"),
          routes: [
            {
              path: "about",
              pathName: this.$t("nav10"),
            },
            {
              path: "about",
              pathName: this.$t("nav11"),
            },
            {
              path: "about",
              pathName: this.$t("nav12"),
            },
            {
              path: "about",
              pathName: this.$t("nav13"),
            },
          ],
        },
      ],
    };
  },
  methods: {
    handleRouter(path) {
      this.$router.push(path);
    },
  },
  computed:{
    isLogin() {
      return this.$store.state.isLogin
    },
    logoUrl() {
      return window.configObj.logoUri
    },
    appName() {
      return window.configObj.appName
    }
  },
};
</script>

<style lang="scss" scoped>
footer {
  background: #1a1a1a;
  color: #fff;
  padding: 35px 0;
}
.page-container{
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
  padding-left: 1rem;
  padding-right: 1rem;
}
.navs-box{
  padding-bottom: 40px;
  .navbar-brand{
    margin-right: 21px;
  }
}
.copyright{
  margin-bottom: 40px;
}
@media (max-width: 768px){
  .navs-box{
    padding-bottom: 20px;
  }
  ul{
    flex-direction: column;
    .navbar-brand{
      margin-right: 0px;
      margin-bottom:10px;
    }
  }
  .copyright{
    margin-bottom: 10px;
  }
}
</style>
