<template>
    <div class="wrapper">
      <header >
      </header>
      <div>
        <div class="banner">
          <div class="banner-main-section">
            <div class="banner-bg">
              <video autoplay loop muted class="banner-video" src="@/assets/img/v9/com-1.mp4"></video>
              <div class="grid-container">
                <div class="abt-banner-text">
                  <h2>Harnessing AI to Transform Financial Markets</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div  data-aos="fade-up" class="section aos-init aos-animate">
          <div  >
            <div  class="text-main-section">
              <div  class="grid-container">
                <div  class="text-grid-main">
                  <div  class="text-left-side">
                    <h2 >Unlock New Trading Possibilities with AI Innovation</h2>
                    <p > At HFT, we are at the forefront of revolutionizing the trading landscape through advanced artificial intelligence (AI) and machine learning (ML) technologies. Our core mission is to empower traders across the globe by delivering innovative, fully automated solutions that simplify complex trading decisions, optimize profitability, and mitigate risk. We believe that AI is the key to transforming the financial markets and creating new opportunities for all types of traders, from individual investors to large financial institutions. </p>
                    <p > Our platform is designed to democratize access to sophisticated financial strategies, making cutting-edge technology available to both novice and experienced traders alike. HFT bridges the gap between traditional trading methodologies and modern AI-driven solutions by integrating real-time market data, predictive algorithms, and intelligent automation. This enables our users to make faster, smarter, and more informed decisions, giving them an edge in today’s fast-paced financial environment. </p>
                    <p > Our AI-driven trading system constantly learns and adapts to market conditions, ensuring that every trade is optimized for the best possible outcomes. By removing emotional biases from trading decisions, we provide a reliable, consistent approach to market movements that helps minimize risks while maximizing potential returns. Our technology works tirelessly around the clock, so you don’t have to. </p>
                    <p > At HFT, we are committed to ongoing innovation and improvement. Our team of AI specialists, financial experts, and technologists collaborate to keep our platform at the forefront of financial technology. Driven by a passion for excellence, we believe AI holds the key to unlocking new opportunities in trading, paving the way for growth, success, and financial freedom for our clients. </p>
                    <p > Whether you're looking to explore new markets or automate your trading, HFT is here to provide the AI-powered solutions, tools, and support you need to succeed. Join us on this exciting journey as we shape the future of auto-trading with AI. </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div  data-aos="fade-up" class="section aos-init aos-animate">
          <div  >
            <div  class="benefits-section-main">
              <div  class="grid-container">
                <div  class="benefits-inner">
                  <h2 >Our Commitment at HFT</h2>
                  <div  class="box-data-grid">
                    <div  class="text-image-box ng-star-inserted">
                      <div  class="image-box"><img  alt="" src="@/assets/img/v9/benefits-icon-1.png"></div>
                      <div  class="image-text"><p > At HFT, we provide opportunities to engage in groundbreaking trading solutions powered by advanced AI. By using our platform, you are part of a movement that’s reshaping the future of automated trading, helping traders unlock new opportunities in global financial markets.</p>
                      </div>
                    </div>
                    <div  class="text-image-box ng-star-inserted">
                      <div  class="image-box"><img  alt="" src="@/assets/img/v9/benefits-icon-2.png">
                      </div>
                      <div  class="image-text">
                        <p > We are dedicated to empowering both individual traders and organizations to realize the full potential of AI in trading. Our mission is to drive innovation, enhance efficiency, and promote sustainable growth by automating and optimizing the trading process with our cutting-edge AI technologies.</p>
                      </div>
                    </div>
                    <div  class="text-image-box ng-star-inserted">
                      <div  class="image-box">
                        <img  alt="" src="@/assets/img/v9/benefits-icon-3.png">
                      </div>
                      <div  class="image-text">
                        <p > For traders, our platform offers exceptional support, giving them access to AI-driven tools that automate decision-making and improve their trading outcomes. By utilizing our advanced AI models, we help traders maximize market potential and achieve consistent growth, making trading simpler and more efficient.</p>
                      </div>
                    </div>
                  <!---->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div  data-aos="fade-up" class="section aos-init aos-animate">
          <div  >
            <div  class="program-section-main">
              <div  class="grid-container">
                <div  class="heading-header">
                  <h2 >Our Vision</h2>
                </div>
                <div  class="text-grid-main">
                  <div  class="text-left-side">
                    <p > At HFT, we envision a future where AI-driven trading is the norm, delivering consistent, profitable outcomes for traders across the globe. Our focus is on creating technology that adapts to the fast-paced, unpredictable nature of financial markets, providing traders with real-time insights and automation capabilities that lead to more informed decision-making. </p>
                    <p > Through continuous innovation, our goal is to set the global standard for AI-powered trading platforms. We aim to be at the forefront of financial technology, leading the charge in making AI and machine learning accessible to everyone in the trading community. </p>
                  </div><div  class="text-right-side">
                    <p > Engaging in the HFT AI Fellows program offers numerous hands-on learning opportunities. Based on your objectives and areas of interest, you may explore topics such as: </p><ul >
                      <li >Core principles of AI and Machine Learning</li>
                      <li >Defining and specifying AI products</li>
                      <li >Managing AI-driven projects</li>
                      <li >Data integration and fusion techniques</li>
                      <li >Designing effective applications</li>
                      <li >Agile methodologies for application development</li>
                      <li >Labeling data for AI features</li>
                      <li >Developing and training AI/ML algorithms and pipelines</li>
                      <li >Techniques for supervised and unsupervised learning</li>
                      <li >Advanced deep learning strategies</li>
                      <li > Combining traditional optimization with AI/ML and physics-based models </li>
                      <li >Visualizing complex data</li>
                      <li >Implementing DevSecOps practices</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div  data-aos="fade-up" class="section aos-init aos-animate">
          <div  >
            <div  class="benefits-section-main">
              <div  class="grid-container">
                <div  class="benefits-inner">
                  <h2 >How We Achieve Our Mission</h2>
                  <div  class="box-data-grid">
                    <div  class="text-image-box ng-star-inserted">
                      <div  class="image-box">
                        <img  alt="" src="@/assets/img/v9/summary-1.png">
                      </div>
                      <div  class="image-text">
                        <p > Advanced AI Algorithms: Our AI selects, manages, and closes positions automatically, utilizing cutting-edge algorithms to predict market trends and optimize trading strategies.</p>
                      </div>
                    </div>
                    <div  class="text-image-box ng-star-inserted">
                      <div  class="image-box">
                        <img  alt="" src="@/assets/img/v9/summary-2.png">
                      </div>
                      <div  class="image-text">
                        <p > Automated Trading: We offer a fully automated trading experience, allowing users to benefit from hands-free, AI-powered trading systems that work around the clock.</p>
                      </div>
                    </div>
                    <div  class="text-image-box ng-star-inserted">
                      <div  class="image-box">
                        <img  alt="" src="@/assets/img/v9/summary-3.png">
                      </div>
                      <div  class="image-text">
                        <p > Market Analysis: HFT’s AI continuously monitors market conditions, ensuring that trades are executed at the most optimal times to maximize profitability and reduce risk.</p>
                      </div>
                    </div>
                    <div  class="text-image-box ng-star-inserted">
                      <div  class="image-box">
                        <img  alt="" src="@/assets/img/v9/summary-4.png">
                      </div>
                      <div  class="image-text">
                        <p > Accessibility: We believe that everyone should have access to sophisticated trading technology. Whether you are a beginner or an experienced trader, HFT is designed to be easy to use while offering powerful tools for success.</p>
                      </div>
                    </div><!---->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div  data-aos="fade-up" class="section aos-init aos-animate">
          <div  >
            <div  class="mid-banner-main">
              <div  class="mid-banner-bg">
                <video  autoplay loop muted class="mid-banner-video" src="@/assets/img/v9/office-video.mp4"></video>
              </div>
            </div>
          </div>
        </div>
        <div  data-aos="fade-up" class="section aos-init aos-animate">
          <div  >
            <div  class="additional-infomation-main mt-4">
              <div  class="grid-container">
                <div  class="header-top">
                  <h2 >4 Easy Steps to Start Trading</h2>
                </div>
                <div  class="box-data-gird grid-top-div">
                  <div  class="box-data-main ng-star-inserted">
                    <div  class="icon-number">
                      <span >Step </span>
                      <p >1</p>
                    </div>
                    <div  class="text-box">
                        <p >Open an Account: Begin by setting up your trading account with HFT. This will be your gateway to accessing our trading platform and resources.</p>
                      </div>
                    </div>
                    <div  class="box-data-main ng-star-inserted">
                      <div  class="icon-number">
                        <span >Step </span>
                        <p >2</p></div><div  class="text-box">
                          <p >Select a Payment Method: Choose your preferred payment method to make your initial deposit. We offer a range of options for your convenience.</p>
                        </div>
                      </div>
                      <div  class="box-data-main ng-star-inserted">
                        <div  class="icon-number"><span >Step </span>
                          <p >3</p>
                        </div>
                        <div  class="text-box">
                          <p >Pick Your Stocks: Browse our selection of available stocks and choose the ones that align with your investment goals.</p>
                        </div>
                      </div>
                      <div  class="box-data-main ng-star-inserted">
                        <div  class="icon-number">
                          <span >Step </span><p >4</p>
                        </div>
                        <div  class="text-box">
                          <p >Initiate an AI Trade: Use our advanced AI-driven tools to execute your trades and start optimizing your trading strategy for success.</p></div></div><!---->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
        <div  data-aos="fade-up" class="section aos-init aos-animate">
          <div  >
            <div  class="infomation-text-main">
              <div  class="grid-container">
                <div  class="text-inner">
                  <h2 >Our Promise</h2>
                  <p > Discover the power of trading with HFT's cutting-edge platform, designed to elevate your trading experience. Our state-of-the-art technology seamlessly integrates advanced AI algorithms with user-friendly interfaces, offering real-time insights and smart trading solutions. </p>
                  <p > Whether you're a seasoned investor or new to the market, our platform provides robust tools and features to help you make informed decisions and achieve your financial goals. With unparalleled security, intuitive navigation, and a comprehensive suite of resources, HFT ensures you have everything you need for a successful trading journey. </p>
                </div>
              </div>
            </div>
          </div>
        </div>        
      </div>
    </div>
  </template>
  <script>
  export default {
    name: "Abouthft",
    data() {
      return {
        // banners: [],
      };
    },
    components: {

    },
    // beforeMount() {
    //   this.getBanners();
    // },
    computed: {
    },
    methods: {
    },
  };
  </script>
  
  <style lang="scss" scoped>
  p{
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  h2{
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  .section{
    z-index: 2;
    position: relative;
  }
  .page-container{
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    box-sizing: border-box;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .banner-main-section{
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    .banner-bg{
      box-sizing: content-box;
      background-position: center;
      background-repeat: no-repeat;
      padding-top: 256px;
      padding-bottom: 160px;
      background-color: #1a1a1a;
      background-size: cover;
      background-position: 45% 18%;
      position: relative;
      width: 100%;
      height: 100%;
      .abt-banner-text{
        h2{
          max-width: 831px;
          font-size: 80px;
          letter-spacing: -1px;
          color: #fff;
          font-weight: 700;
          line-height: 115%;
          margin: 0;
        }
      }
    }
  }
  .banner-video{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
  }
  .grid-container{
    position: relative;
    z-index: 1;
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    max-width: 75rem;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    box-sizing: border-box;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
    font-family: Inter,sans-serif;
  }
  .abt-banner-text{
    text-align: left;
    color: #fff;
  }
  .text-main-section{
    padding-top: 8rem;
    padding-bottom: 6rem;
    background: #fff;
  } 
  .text-main-section .text-grid-main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .text-left-side{
      display: flex;
      flex-direction: column;
      grid-row-start: 1;
      grid-row-end: 3;
      grid-column-start: 1;
      h2{
        color: #1A1A1A;
        font-size: 55px;
        font-family: arial;
        margin: 0 0 25px;
        font-weight: 700;
        line-height: normal;
      }
      p{
        color: #1A1A1A;
        font-weight: 400;
        margin-bottom: 25px;
        line-height: 28.8px;
        font-family: arial;
        font-size: 18px;
      }
    }
  }
  .benefits-section-main{
    padding-top: 6rem;
    padding-bottom: 6rem;
    background: #fff;
    .benefits-inner{
      h2{
        color: #1A1A1A;
        font-size: 55px;
        font-family: arial;
        margin: 0;
        font-weight: 700;
        margin-bottom: 5rem;
        line-height: normal;
      }
      .box-data-grid {
        display: flex;
        gap: 3rem;
        .text-image-box{
          width: 33.33%;
          p{
              color: #1A1A1A;
              font-weight: 400;
              margin-bottom: 25px;
              line-height: 28.8px;
              font-family: arial;
              font-size: 18px;
              max-width: 324px;
          }
        }
      }
    }
  }
  .program-section-main{
    padding: 6rem 0rem;
    background-color: #fff;
    .grid-container{
      display: block;
    }
    .heading-header{
      h2{
        color: #1A1A1A;
        font-size: 55px;
        font-family: arial;
        margin: 0;
        font-weight: 700;
        margin-bottom: 5rem;
        line-height: normal;
      }
    }
    .text-grid-main{
      display: flex;
      gap: 56px;
      .text-left-side{
          width: 50%;
          p{
            font-size: 16px;
            font-weight: 500;
          }
      }
      .text-right-side{
        width: 50%;
        p{
          color: #1A1A1A;
          font-weight: 400;
          line-height: 28.8px;
          font-family: arial;
          font-size: 18px;
          margin: 0;
        }
        ul{
          margin: 0 0 0 30px;
          list-style-type: disc;
          li{
            color: #1A1A1A;
            font-weight: 400;
            line-height: 28.8px;
            font-family: arial;
            font-size: 18px;
            margin: 0;
          }
        }
      }
    }
  }
  .mid-banner-video{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
  .mid-banner-main{
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    .mid-banner-bg{
      padding: 25%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .wrapper{
    background-color: #fff;
  }
  .mt-4{
    margin-top: 4rem;
  }
  .additional-infomation-main{
    background: #fff;
    padding: 0rem 0rem 6rem;
    .grid-container{
      display: block;
    }
    .header-top{
      h2{
        color: #1A1A1A;
        font-size: 55px;
        font-family: arial;
        margin: 0;
        font-weight: 700;
        margin-bottom: 5rem;
        line-height: normal;
      }
    }
    .box-data-gird{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      &.grid-top-div{
        border-bottom: 1px solid #9B9B9B;
        margin-bottom: 50px;
        padding-bottom: 50px;
      }
      .box-data-main{
        background-color: #f6f6f6;
        font-size: 14px;
        line-height: 22px;
        font-family: arial;
        padding: 20px 40px;
        .icon-number{
          background: #257799;
          width: 64px;
          height: 64px;
          border-radius: 100px;
          display: flex;
          align-items: center;
          color: #fff;
          font-weight: 700;
          font-size: 16px;
          font-family: arial;
          flex-direction: column;
          line-height: normal;
          justify-content: center;
          p{
            margin: 0;
          }
        }
        .text-box{
          p{
            color: #1A1A1A;
            font-weight: 400;
            margin-bottom: 25px;
            line-height: 28.8px;
            font-family: arial;
            font-size: 16px;
          }
        }
      }
    }
  }
  .infomation-text-main{
    padding: 6rem 0rem;
    .grid-container{
      display: block;
    }
    .text-inner{
      h2{
        color: #1A1A1A;
        font-size: 55px;
        font-family: arial;
        margin: 0;
        font-weight: 700;
        margin-bottom: 2rem;
        line-height: normal;
      }
      p{
        color: #1A1A1A;
        font-weight: 400;
        margin-bottom: 25px;
        line-height: 28.8px;
        font-family: arial;
        font-size: 18px;
      }
    }
  }
  @media(max-width: 1440px) {
  }
  @media(max-width: 1024px) {
    .banner-main-section{
      .banner-bg{
        .abt-banner-text{
          h2{
            font-size: 60px;
          }
        }
      }
    }
    .text-main-section .text-grid-main {
      grid-template-columns: auto;
      .text-left-side{
        grid-row-start: 2;
        h2{
          font-size: 32px;
          margin-top: 15px;
          margin-bottom: 0;
        }
        p{
          font-size: 16px;
          margin-bottom: 1px;
        }
      }
    }
  }
  @media(max-width: 991px) {
    .benefits-section-main{
      padding-top: 3rem;
      padding-bottom: 3rem;
      .benefits-inner{
        h2{
          font-size: 40px;
          margin-bottom: 3rem;
          text-align: center;
        }
        .box-data-grid {
          flex-direction: column;
        align-items: center;
        gap: 1rem;
          .text-image-box{
            width: 100%;
            p{
              max-width: 100%;
            }
          }
        }
        .text-image-box{
          text-align: center;
        }
      }
    }
    .program-section-main{
      padding: 3rem 0rem;
      .heading-header{
        h2{
          font-size: 40px;
          margin-bottom: 3rem;
          text-align: center;
        }
      }
      .text-grid-main{
        flex-direction: column;
        gap: 5px;
        .text-left-side{
            width: 100%;
        }
        .text-right-side{
          width: 100%;
        }
      }
    }
    .additional-infomation-main{
      padding: 0rem 0rem 3rem;
      .header-top{
        h2{
          font-size: 40px;
          margin-bottom: 3rem;
          text-align: center;
        }
      }
      .box-data-gird{
        grid-template-columns: auto;
        &.grid-top-div{
          margin-bottom: 30px;
          padding-bottom: 30px;
        }
        .box-data-main{
          background-color: #f6f6f6;
          font-size: 14px;
          line-height: 22px;
          font-family: arial;
          padding: 20px 40px;
          .icon-number{
            background: #257799;
            width: 64px;
            height: 64px;
            border-radius: 100px;
            display: flex;
            align-items: center;
            color: #fff;
            font-weight: 700;
            font-size: 16px;
            font-family: arial;
            flex-direction: column;
            line-height: normal;
            justify-content: center;
            p{
              margin: 0;
            }
          }
          .text-box{
            p{
              color: #1A1A1A;
              font-weight: 400;
              margin-bottom: 25px;
              line-height: 28.8px;
              font-family: arial;
              font-size: 16px;
            }
          }
        }
      }
    }
    .infomation-text-main{
      padding: 3rem 0rem;
      .text-inner{
        h2{
          font-size: 40px;
          margin-bottom: 1rem;
          text-align: center;
        }
      }
    }
  }
  @media (max-width: 768px){
    .banner-main-section{
      .banner-bg{
        .abt-banner-text{
          h2{
            font-size: 40px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    
  }
  </style>